/* eslint-env browser */
import { css, Global } from '@emotion/core';
import Head from 'next/head';
import React, { useEffect } from 'react';

import { TemplateProps } from './BaseTemplate';

declare global {
  interface Window {
    themeOverride: object;
  }
}

const RsvpTemplate: React.FC<TemplateProps> = ({ themeOverride }) => {
  useEffect(() => {
    const source = process.env.RSVP_BUNDLE || '';
    const tag = document.createElement('script');
    tag.async = false;
    tag.src = source;
    document.getElementsByTagName('body')[0].appendChild(tag);
    if (themeOverride) {
      window.themeOverride = JSON.parse(themeOverride);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  function isIE() {
    if (typeof navigator === 'undefined') return false;
    const myNav = navigator.userAgent.toLowerCase();
    return myNav.indexOf('msie') !== -1 ? parseInt(myNav.split('msie')[1], 10) : false;
  }

  const unsupportedBrowser = () => {
    return isIE() && isIE() < 9;
  };

  return (
    <>
      <Head>
        <link rel="shortcut icon" href={`${process.env.RSVP_ROOT}/favicon.ico`} />
        <link rel="stylesheet" href={process.env.RSVP_CSS_BUNDLE} />
        <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no" />
        <meta name="robots" content="noindex, nofollow" />
        <script src={process.env.RSVP_NEW_RELIC} />
        <script src={`${process.env.RSVP_ROOT}/icons.js`} />
        <Global
          styles={css`
            .error-message {
              background-color: rgba(245, 166, 35, 0.2);
              margin-bottom: 15px;
              text-align: left;
              font-size: 14px;
              padding: 13px 20px;
              border-left: 5px solid rgb(245, 166, 35);
            }
          `}
        />
      </Head>

      <noscript>You need to enable JavaScript to run this app.</noscript>
      {unsupportedBrowser() && (
        <div id="browser-not-supported" className="error-message">
          Oops! We’re having trouble with your browser. Please try again on another browser or device.
        </div>
      )}
      <div id="root" />
    </>
  );
};

export default RsvpTemplate;
