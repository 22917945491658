import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';

import { Maybe } from '../../../generated/graphql';

interface DateCountdownProps {
  hideDate?: Maybe<boolean>;
  weddingDate?: Maybe<string>;
  weddingDateFormat?: Maybe<string>;
  weddingDateRangeEnd?: string;
  weddingDateRangeStart?: string;
}

type DateCountdownReturn = number | null;

const dateCountdown = ({
  hideDate,
  weddingDate,
  weddingDateFormat,
  weddingDateRangeStart,
}: DateCountdownProps): DateCountdownReturn => {
  let differenceDays = null;
  if (weddingDate && !hideDate) {
    if (weddingDateFormat === 'range' && weddingDateRangeStart) {
      differenceDays = differenceInCalendarDays(new Date(weddingDateRangeStart), Date.now());
    } else {
      differenceDays = differenceInCalendarDays(new Date(weddingDate), Date.now());
    }
  }
  return differenceDays;
};

export default dateCountdown;
