import React, { useContext } from 'react';

import styled from '../../lib/styled';
import Picture from '../Picture';
import { WWContext } from '../WWContext';
import Header from './Header';

const HeroHeader = styled(Header)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
`;
const Overlay = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.2;
  top: 0;
`;
const HeroPicture = styled(Picture)`
  width: 100%;
`;
const HeroWrap = styled.div`
  width: 100%;
  position: relative;
  ${(props) => props.theme.components.Hero}
`;

interface Props {
  className?: string;
  src: string;
  headerOnHero: boolean;
  landscapeCrop?: string;
  'data-testid'?: string;
}

const HeroImage: React.FC<Props> = ({ className, src, headerOnHero, landscapeCrop, ...rest }) => {
  const { data } = useContext(WWContext);
  let alt = 'Home Page Banner';
  const { firstName, fianceFirstName } = data.weddingWebsiteResult;

  if (firstName && fianceFirstName) {
    alt = `${firstName} & ${fianceFirstName} Home Page Banner`;
  }

  return (
    <HeroWrap className={className}>
      {headerOnHero && <HeroHeader heroVersion />}
      <HeroPicture url={src} crop={landscapeCrop} alt={alt} {...rest} />
      {headerOnHero && <Overlay />}
    </HeroWrap>
  );
};

export default HeroImage;
