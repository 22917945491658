export const colorAlpha = (hex: string | string[], alpha: string) => {
  if (Array.isArray(hex)) {
    hex = hex[0];
  }
  let channels: string[] = [];
  if (!hex?.match(/^#/)) {
    return hex;
  }
  const letters = hex.match(/[\w\d]/g) || [];
  if (letters.length === 3) {
    channels = hex.match(/[\w\d]/g) || [];
    channels = channels.map((l) => `${l}${l}`);
  } else if (letters.length === 6) {
    channels = hex.match(/[\w\d][\w\d]/g) || [];
  } else {
    return hex;
  }
  const [r, g, b] = channels.map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};
