import getDatePartValue from './getDatePartValue';

interface FormatOptions {
  month: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | undefined;
}

const dateRangeFormatted = (startDateStr?: string, endDateStr?: string, formatOptions?: FormatOptions) => {
  if (!startDateStr || !endDateStr) return null;

  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  const formatter = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
    ...(formatOptions || {}),
  });
  const startParts = formatter.formatToParts(startDate);
  const endParts = formatter.formatToParts(endDate);
  const startYear = getDatePartValue(startParts, 'year');
  const endYear = getDatePartValue(endParts, 'year');
  const startMonth = getDatePartValue(startParts, 'month');
  const endMonth = getDatePartValue(endParts, 'month');
  const startDay = getDatePartValue(startParts, 'day');
  const endDay = getDatePartValue(endParts, 'day');

  let formattedRange = null;
  if (startYear !== endYear) {
    formattedRange = `${startMonth}.${startDay}, ${startYear}–${endMonth}.${endDay}, ${endYear}`;
  } else if (startMonth !== endMonth) {
    formattedRange = `${startMonth}.${startDay}–${endMonth}.${endDay}, ${startYear}`;
  } else {
    formattedRange = `${startMonth}.${startDay}–${endDay}, ${startYear}`;
  }

  // using "." to indicate month day separation points
  // using ", " to indicate day year separation points
  // replace separation points according to the format
  if (!formatOptions?.month) {
    // ie. May 5, 2020
    formattedRange = formattedRange.split('.').join(' ');
  } else if (formatOptions.month === 'numeric') {
    // ie. 5.5.2020
    formattedRange = formattedRange.split(', ').join('.');
  }

  return formattedRange;
};

export default dateRangeFormatted;
