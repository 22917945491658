import React from 'react';

import { QuestionItemFragment } from '../../generated/graphql';
import styled from '../../lib/styled';
import { BaseItemProps } from '../PageContents';
import { Item, ItemDescription } from './BusinessItem';
import { H4 } from './HeadlineItem';

const QuestionFlourish = styled.div`
  ${({ theme }) => theme.components.QuestionFlourish}
`;

const QuestionItem: React.FC<BaseItemProps> = ({ item, className }) => {
  const { question, answer, id } = item as QuestionItemFragment;
  return (
    <Item className={className} data-testid={`question-item-${id}`}>
      <QuestionFlourish />
      <H4>{question}</H4>
      <ItemDescription>{answer}</ItemDescription>
    </Item>
  );
};

export default QuestionItem;
