import { useRouter } from 'next/router';

/**
 * Returns the current route name. Empty string for home
 */
export const useCurrentRoute = (): string => {
  const { query } = useRouter();

  if (query.route?.length) {
    return query.route[0];
  }

  return '';
};
