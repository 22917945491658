import Head from 'next/head';
import React, { useContext, useEffect } from 'react';

import { css, Global } from '@emotion/core';
import { cleanUserInput } from '../../lib/cleanUserInput';
import styled from '../../lib/styled';
import { themeFont } from '../../lib/themeFont';
import { WWContext } from '../WWContext';

const RegistryNote = styled.h4`
  line-height: 1;
  ${themeFont('s6')}
  margin: 12px auto;
  text-align: center;
  max-width: 900px;
`;

const APPLICATION = 'wedding website';

const RegistryTemplate = () => {
  const { data } = useContext(WWContext);
  const note = data.weddingWebsiteResult.registryNote ?? '';
  const withNote = note.trim() !== '';

  // Effect is needed for SSR
  useEffect(() => {
    const source = process.env.REG_PRODUCT_GRID_JS || '';
    const tag = document.createElement('script');
    tag.async = false;
    tag.src = source;
    document.getElementsByTagName('body')[0].appendChild(tag);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  if (data.weddingWebsiteResult.userUuid) {
    return (
      <>
        <Head>
          <link href={process.env.REG_PRODUCT_GRID_CSS} rel="stylesheet" />
          <script
            type="text/javascript"
            src={`${process.env.ASSET_URL ? process.env.ASSET_URL : ''}/unionIconScript.js`}
          />
        </Head>
        <Global
          styles={css`
            :focus {
              outline: transparent;
            }
            ol,
            ul {
              list-style: initial;
              margin: initial;
              padding: initial;
            }
          `}
        />
        {withNote && <RegistryNote dangerouslySetInnerHTML={{ __html: cleanUserInput(note) }} />}

        <div
          id="products-grid-app-root"
          data-member-id={data.weddingWebsiteResult.userUuid}
          data-application={APPLICATION}
        />
      </>
    );
  }

  return <>{withNote && <RegistryNote>{note}</RegistryNote>}</>;
};

export default RegistryTemplate;
