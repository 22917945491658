const breakpoints = [423, 768, 1280];

interface BreakpointMap {
  [key: string]: string;
}

const keyAndMediaQuery = (breakpoint: number, minOrMax: string) => {
  const key: string = `${minOrMax}${breakpoint}`;
  const mediaQuery: string = `@media (${minOrMax}-width: ${breakpoint}px)`;
  return [key, mediaQuery];
};

const breakpointMap: BreakpointMap = breakpoints.reduce((obj, bp) => {
  const [maxKey, maxQuery] = keyAndMediaQuery(bp, 'max');
  const [minKey, minQuery] = keyAndMediaQuery(bp, 'min');
  obj[maxKey] = maxQuery;
  obj[minKey] = minQuery;
  return obj;
}, {} as BreakpointMap);

export default breakpointMap;
