import React, { useContext } from 'react';

import { BaseItemProps } from '../PageContents';
import RSVPButton from '../RSVPButton';
import { WWContext } from '../WWContext';
import ScheduleEvent from './ScheduleEvent';

const EventGroupItem: React.FC<BaseItemProps> = () => {
  const { data } = useContext(WWContext);
  const { events } = data;

  return (
    <>
      <RSVPButton />
      {events.map((event) => (
        <ScheduleEvent key={event.id} event={event} />
      ))}
    </>
  );
};

export default EventGroupItem;
