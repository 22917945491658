import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

import { WWQueryProps } from './RegistryContext';

const Seo: React.FC<WWQueryProps> = ({ data }) => {
  const { query } = useRouter();
  if (!data || data.weddingWebsiteResult?.__typename !== 'WWS_WeddingWebsiteV2') {
    return null;
  }
  const {
    firstName = '',
    lastName = '',
    fianceFirstName = '',
    fianceLastName = '',
    coverPhoto,
    slug,
    searchEngineIndexable,
  } = data.weddingWebsiteResult;
  const { route = [] } = query || {};
  let coverPhotoUrl = '';
  if (coverPhoto) {
    coverPhotoUrl = `https:${coverPhoto.path}`;
  }

  const coupleNames = `${firstName} ${lastName} and ${fianceFirstName} ${fianceLastName}'s`;
  const description = `Welcome to ${coupleNames} Wedding Website! View photos, directions, registry details and more at The Knot.`;
  const title = `${coupleNames} Wedding Website - The Knot`;

  const canonical = `${process.env.WWS_HOST}/${slug}`;
  const noIndexNoFollowPage = ['photos'].includes(route[0]);
  const noindex = !searchEngineIndexable || noIndexNoFollowPage;

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonical} />
      {noindex && (
        <>
          <meta name="googlebot" content="noindex,nofollow" />
          <meta name="robots" content="noindex,nofollow" />
        </>
      )}
      <meta property="og:url" content={canonical} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={coverPhotoUrl} />
      <meta property="og:type" content="website" />
    </Head>
  );
};

export default Seo;
