import responsiveBackgroundImage from '../../lib/responsiveBackgroundImage';
import styled from '../../lib/styled';
import zIndices from '../../lib/zIndices';

interface BackgroundProps {
  offsetBackgroundForNavBar?: boolean;
}

const Background = styled.div<BackgroundProps>`
  ${(props) =>
    responsiveBackgroundImage({
      url: props.theme.images.background,
      responsiveImages: props.theme.responsiveImages?.background,
      sm: 800,
      md: 1200,
      lg: 1600,
      xl: 2000,
    })}
  background-color: ${(props) => props.theme.colors.background};
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: ${zIndices.background};

  // Mobile only: Shift background down from behind mobile nav bar
  ${(props) => (props.offsetBackgroundForNavBar ? 'top: 56px;' /* height of the mobile nav bar */ : 'top: 0;')}
  @media (min-width: 768px) {
    top: 0;
  }

  // bug fix for safari mobile: missing background color when bottom action bar is hidden on scroll
  bottom: -120px;

  ${(props) => props.theme.components.BackgroundImage}
`;
export default Background;
