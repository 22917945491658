import { useContext, useMemo } from 'react';

import { WWContext } from '../components/WWContext';
import { WwsPagesFragment } from '../generated/graphql';
import { useRsvpIsVisible } from './useRsvpIsVisible';
import { useIsLiteSite } from './useIsLiteSite';

export const useNavLink = (): WwsPagesFragment['pages'] => {
  const { data } = useContext(WWContext);
  const rsvpNavItem = useRsvpIsVisible();
  const isLiteSite = useIsLiteSite();

  let pages = data.weddingWebsiteResult.pages?.filter((p) => {
    if (!p.title) {
      return false;
    }

    // Guest Book && Mysterious details page
    if (
      (p.type === 'ContentPage' && p.routeName === 'details') ||
      (p.type === 'GuestBookPage' && p.routeName === 'guest-book')
    ) {
      return false;
    }

    return p.show || p.type === 'WeddingPage';
  });

  // Rename Our Wedding to Home for legacy reasons
  pages = useMemo(
    () =>
      pages?.map((p) => {
        if (p.type === 'WeddingPage') {
          return {
            ...p,
            title: 'Home',
          };
        }

        return p;
      }),
    [pages]
  );

  // For Lite Site hide the WeddingPage to prevent multiple "Home" pages
  if (isLiteSite) {
    pages = useMemo(() => pages?.filter((p) => p.type !== 'WeddingPage'), [pages]);
  }

  if (pages && rsvpNavItem) {
    // To keep consistency we create a fake rsvp page item only used for nav. This can be removed after
    // rsvpAsPage experiment is called
    return [...pages, { id: 0, title: 'RSVP', routeName: 'rsvp', items: [], type: 'RsvpNavItem' }];
  } else {
    return pages;
  }
};
