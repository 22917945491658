import React, { useContext, useMemo } from 'react';

import { getInitials } from '../../../../lib/getInitials';
import styled from '../../../../lib/styled';
import { themeFont } from '../../../../lib/themeFont';
import { useCurrentRoute } from '../../../../lib/useCurrentRoute';
import { WWContext } from '../../../WWContext';

const TitleIntials = styled.h2`
  align-items: center;
  display: flex;
  height: 56px;
  justify-content: center;
  width: 100%;
  ${themeFont('s3')}
`;

const MobilePageTitle = () => {
  const { data } = useContext(WWContext);
  const currentRoute = useCurrentRoute();
  const { firstName = '', fianceFirstName = '', pages } = data.weddingWebsiteResult;

  const activePath = useMemo(() => pages?.find((p) => p.routeName === currentRoute), [pages, currentRoute]);

  if (!activePath) {
    return null;
  }
  const initials = getInitials({ firstName, fianceFirstName }) || activePath.title;
  const title = activePath.routeName === '' ? initials : activePath.title;

  return <TitleIntials>{title}</TitleIntials>;
};

export default MobilePageTitle;
