import { ThemeProvider } from 'emotion-theming';

import useThemeStyles from '../../lib/useThemeStyles';
import CardLayout from '../Layout/LiteSite/CardLayout';
import { TemplateProps } from './BaseTemplate';

const LiteSiteTemplate: React.FC<TemplateProps> = ({ themeOverride }) => {
  const themeStyles = useThemeStyles(themeOverride);

  // TODO: Match Page item to proper layout items
  return (
    <ThemeProvider theme={themeStyles}>
      <CardLayout />
    </ThemeProvider>
  );
};

export default LiteSiteTemplate;
