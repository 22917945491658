import React from 'react'

export interface SVGComponentProps {
  className?: string
  title?: string
}

interface SVGBaseProps {
  className?: string
  labeledBy: string
  path: any
  title: string
}
const SVGBase = (props: SVGBaseProps) => {
  const { className, labeledBy, path, title } = props
  return (
    <svg
      aria-labelledby={labeledBy}
      className={className}
      role="img"
      version="1.1"
      viewBox="0 0 32 32"
    >
      <title id={labeledBy}>{title}</title>
      {path}
    </svg>
  )
}
export default SVGBase
