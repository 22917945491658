import { ThemeProvider } from 'emotion-theming';
import { NextPage } from 'next';
import { useRouter } from 'next/dist/client/router';
import Head from 'next/head';
import React from 'react';
import Cookies from 'universal-cookie';

import Login from '../../../components/Login';
import Main from '../../../components/Main';
import { WWProvider } from '../../../components/WWContext';
import { useWeddingWebsiteQuery } from '../../../generated/graphql';
import { isClient } from '../../../lib/utils';

interface Props {
  sessionToken?: string;
  pin?: string;
}

const Slug: NextPage<Props> = ({ sessionToken, pin }) => {
  const { query } = useRouter();

  const { data } = useWeddingWebsiteQuery({
    variables: {
      pin,
      slug: query.slug as string,
      themeId: (query.themeId as string) || null,
    },
    context: {
      slug: query.slug,
      sessionToken,
    },
  });

  if (data?.weddingWebsiteResult?.__typename === 'WWS_Error') {
    return (
      <ThemeProvider theme={data.weddingWebsiteResult?.theme?.themeStyles[0]?.styles}>
        <Login />
      </ThemeProvider>
    );
  } else if (data?.weddingWebsiteResult?.__typename === 'WWS_WeddingWebsiteV2') {
    return (
      <WWProvider data={data}>
        <Main />
      </WWProvider>
    );
  }

  return (
    <>
      <Head>
        <meta name="googlebot" content="noindex,nofollow" />
        <meta name="robots" content="noindex,nofollow" />
      </Head>
      <div>Oops! Our system is having trouble right now. Please try again in a few minutes.</div>
    </>
  );
};

Slug.getInitialProps = (ctx) => {
  const cookies = isClient() ? new Cookies() : new Cookies(ctx?.req?.headers.cookie);

  const sessionToken = cookies.get('xo-session-token');
  const pin = cookies.get('wws-pin');

  return {
    sessionToken,
    pin,
  };
};

export default Slug;
