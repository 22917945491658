import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import styled from '../lib/styled';
import { useRsvpIsVisible } from '../lib/useRsvpIsVisible';
import ActionButton from './ActionButton';

const Button = styled(ActionButton)`
  margin: 0 auto;
  width: fit-content;
`;

const RSVPButton: React.FC = () => {
  const { query } = useRouter();
  const showRsvp = useRsvpIsVisible();

  if (!showRsvp) {
    return null;
  }

  return (
    <Link
      href={{
        pathname: '/[pathPrefix]/[slug]/[route]',
        query: {
          ...query,
          route: 'rsvp',
        },
      }}
    >
      <Button>RSVP</Button>
    </Link>
  );
};

export default RSVPButton;
