import React from 'react';
import snakeCase from 'lodash/snakeCase';
import { GifItemFragment, PhotoItemFragment } from '../../generated/graphql';
import { extractParamsFromMediaUrl } from '../../lib/mediaApiUrl';
import styled from '../../lib/styled';
import { BaseItemProps } from '../PageContents';
import Picture from '../Picture';

const PhotoItemWrap = styled.div<{ landscape: boolean }>`
  margin: 0 auto;
  padding: 15px;
  ${(props) => !props.landscape && 'max-width: 230px;'}

  @media(min-width: 540px) {
    max-width: ${(props) => (props.landscape ? '540px' : '370px')};
  }
`;

const PhotoItem: React.FC<BaseItemProps> = ({ item, className }) => {
  const { mediaUrl, id, type } = item as PhotoItemFragment | GifItemFragment;

  let landscape = false;
  let rotation;
  if (item.__typename === 'WWS_PhotoItem') {
    const aspectRatioName = item.aspectRatio?.name || '';
    landscape = ['4:3', '3:2'].includes(aspectRatioName);
    rotation = item.rotation;
  }

  const { crop, url } = extractParamsFromMediaUrl(mediaUrl);

  return (
    <PhotoItemWrap
      className={className}
      landscape={landscape}
      data-testid={`${snakeCase(type).replace('_', '-')}-${id}`}
    >
      <Picture url={url} alt="" sm={510} md={510} lg={510} xl={510} crop={crop} rotation={rotation} />
    </PhotoItemWrap>
  );
};

export default PhotoItem;
