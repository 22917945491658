import snakeCase from 'lodash/snakeCase';
import React from 'react';

import { AccommodationItemFragment, ActivityItemFragment, TransportationItemFragment } from '../../generated/graphql';
import { cleanUserInput } from '../../lib/cleanUserInput';
import dateTimeFormatted from '../../lib/dateFormatter/dateTimeFormatted';
import styled from '../../lib/styled';
import { themeFont } from '../../lib/themeFont';
import ActionButton from '../ActionButton';
import { BaseItemProps } from '../PageContents';
import Picture from '../Picture';
import { P } from './ParagraphItem';

export const Item = styled.div`
  padding: 1px 20px;
  max-width: 550px;
  margin: 0 auto 12px;
  text-align: center;
  ${(props) => props.theme.components.ContentItem}
`;
export const Name = styled.h4`
  color: rgb(31, 31, 31);
  line-height: 1;
  margin: 28px 0;
  ${themeFont('s3')}
`;
export const ItemPicture = styled(Picture)`
  margin: 1rem auto 0 auto;
`;
export const ItemDescription = styled(P)`
  line-height: 1.75;
  margin: 32px 0;
  ${themeFont('s6')}
  a {
    text-decoration: underline;
    ${(props) => props.theme.components.Link};
  }
`;
const Info = styled.div`
  margin: 16px 0;
`;

const Address = styled.div`
  font-style: normal;
  line-height: 1;
  ${themeFont('s5')}
`;
const PhoneNumber = styled.div`
  font-style: normal;
  line-height: 1;
  margin-top: 8px;
  ${themeFont('s5')}
`;

const CheckInOut = styled.span`
  font-style: normal;
  line-height: 1;
  ${themeFont('s6')}
`;

const AccomTitle = styled.span`
  ${themeFont('s6')}
  font-weight: bold;
`;

const AccomInfo = styled.div`
  margin: 16px 0;
  ${themeFont('s6')}
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

type BusinessItem = AccommodationItemFragment | ActivityItemFragment | TransportationItemFragment;

const BusinessItem: React.FC<BaseItemProps> = ({ item, className }) => {
  const { id, type, description, name, address, phone, email, website, photo } = item as BusinessItem;
  const mediaUrl = photo?.mediaUrl || '';

  let checkInDate, checkOutDate, guestCode, rate;

  if (item.__typename === 'WWS_AccommodationItem') {
    checkInDate = dateTimeFormatted(item.checkInDate, 'weekday');
    checkOutDate = dateTimeFormatted(item.checkOutDate, 'weekday');
    guestCode = item.guestCode;
    rate = item.rate;
  }

  let baseMediaUrl;
  if (mediaUrl) {
    const transformIndex = mediaUrl.indexOf('~');
    if (transformIndex !== -1) {
      baseMediaUrl = mediaUrl.slice(0, transformIndex);
    } else {
      baseMediaUrl = mediaUrl;
    }
  }

  let absoluteWebsite;
  if (website) {
    if (!website.match(/\/\//)) {
      absoluteWebsite = `//${website}`;
    } else {
      absoluteWebsite = website;
    }
  }

  return (
    <Item className={className} data-testid={`${snakeCase(type).replace('_', '-')}-${id}`}>
      {name && <Name>{name}</Name>}
      {baseMediaUrl && (
        <ItemPicture url={baseMediaUrl} sm={360} md={510} lg={510} xl={510} alt={`Picture of ${name}`} />
      )}
      {(address || phone) && (
        <Info>
          {address && <Address>{address}</Address>}
          {phone && <PhoneNumber>{phone}</PhoneNumber>}
        </Info>
      )}

      {(checkInDate || checkOutDate) && (
        <AccomInfo>
          {checkInDate && (
            <CheckInOut>
              <AccomTitle>Check-In: </AccomTitle>
              {checkInDate}
            </CheckInOut>
          )}
          {checkInDate && checkOutDate && ' | '}
          {checkOutDate && (
            <CheckInOut>
              <AccomTitle>Check-Out: </AccomTitle>
              {checkOutDate}
            </CheckInOut>
          )}
        </AccomInfo>
      )}

      {rate && (
        <Info>
          <AccomTitle>Rate: {rate}</AccomTitle>
        </Info>
      )}

      {guestCode && (
        <Info>
          <AccomTitle>Use Code: {guestCode}</AccomTitle>
        </Info>
      )}

      {description && (
        <ItemDescription
          dangerouslySetInnerHTML={{
            __html: cleanUserInput(description),
          }}
        />
      )}
      {(absoluteWebsite || email) && (
        <Actions>
          {absoluteWebsite && (
            <ActionButton target="_blank" href={absoluteWebsite} rel="nofollow">
              Website
            </ActionButton>
          )}
          {email && (
            <ActionButton rel="nofollow" href={`mailto:${email}`}>
              Email
            </ActionButton>
          )}
        </Actions>
      )}
    </Item>
  );
};

export default BusinessItem;
