import React, { useContext } from 'react';

import styled from '../../lib/styled';
import { WWContext } from '../WWContext';
import InitialsDateLockup from './InitialsDateLockup';
import Theme from './Theme';
import ThemeAttribution from './ThemeAttribution';
import TopMatter from './TopMatter';

interface Props {
  children: React.ReactElement;
  headerOnHeroOverride?: boolean;
}

const Content = styled.div`
  padding: 16px 0;
  width: 100%;
`;

export const Layout: React.FC<Props> = ({ children }) => {
  const { data } = useContext(WWContext);

  const { firstName, fianceFirstName, theme } = data.weddingWebsiteResult;

  const footer = (
    <>
      <InitialsDateLockup firstName={firstName} fianceFirstName={fianceFirstName} />
      <ThemeAttribution designer={theme?.themeDesigner} />
    </>
  );

  return (
    <Theme footer={footer}>
      <TopMatter />
      <Content>{children}</Content>
    </Theme>
  );
};
export default Layout;
