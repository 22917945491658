import fetch from 'isomorphic-unfetch';
import React, { useEffect, useState } from 'react';

import { WeddingWebsiteQuery } from '../generated/graphql';
import honeybadger from '../lib/honeybadger';

export interface WWQueryProps {
  data: WeddingWebsiteQuery;
}

export interface CoupleInfo {
  fianceFirstName: string;
  firstName: string;
  id: string;
  isActive: boolean;
  isCashRegistryActive: boolean;
  isDeleted: boolean;
  isHiddenProducts: boolean;
  isHiddenProductsInWebsite: boolean;
  isHiddenRegistriesInWebsite: boolean;
  isHiddenSearchEngines: boolean;
  isManualOverrided: boolean;
  isPrivate: boolean;
  registries: Registry[];
  stripeAccountStatus: string;
  charity: Charity | null;
  memberUuid: string;
  hasTransactionalRegistry: boolean;
}

export interface Registry {
  id: string;
  registryUuid: string;
  registryType: number;
  manualRegistryName?: null | string;
  manualRegistryUrl?: null | string;
  hiddenCoupleSearch: boolean;
  hiddenWws: boolean;
  isDeleted: boolean;
  retailer: Retailer;
}

export interface Retailer {
  id: number;
  retailerName: string;
  description: string;
  eventType: number;
  website: null | string;
  domain: null | string;
  additionalInfoUrl: null | string;
  storeInfoPageUrl: null | string;
  fullLogoImageUrl: string;
  smallLogoImageUrl: string;
  privacyPolicyUrl: string;
  registryCreateLogoImageUrl: null | string;
  returnAndExchangePolicy: null | string;
  oneColSortOrder: number;
  twoColSortOrder: null;
  threeColSortOrder: null;
  fourColSortOrder: number;
  isPartner: boolean;
  isDeleted: boolean;
  tier: number | null;
  benefits: null | string;
  slug: string;
}

export interface Charity {
  charityId: string;
  charityName: string;
  personalMessage: string;
  ein: string;
  charityUrl: string;
  charityTypeCode: string;
  isDeleted: boolean;
}

interface Context {
  coupleInfo?: CoupleInfo;
}

export const RegistryContext = React.createContext<Context>({});

export const RegistryProvider: React.FC<WWQueryProps> = ({ children, data }) => {
  const [coupleInfo, setCoupleInfo] = useState<CoupleInfo | undefined>(undefined);

  useEffect(() => {
    const getData = async () => {
      if (data?.weddingWebsiteResult?.__typename === 'WWS_WeddingWebsiteV2' && data.weddingWebsiteResult.userUuid) {
        try {
          // Actually check for status code 🤦‍♂️
          const apiData = await fetch(
            `${process.env.REGISTRY_CORE}/v2/members/${data.weddingWebsiteResult.userUuid}/public-summary?eventType=wedding`
          ).then((resp) => resp.json());
          setCoupleInfo(apiData);
        } catch (error) {
          honeybadger.notify(error, {
            name: 'Registry Couple API',
            context: {
              apiResponse: error,
              userUuid: data.weddingWebsiteResult.userUuid,
            },
          });
        }
      }
    };

    getData();
  }, [data.weddingWebsiteResult]);

  return <RegistryContext.Provider value={{ coupleInfo }}>{children}</RegistryContext.Provider>;
};
