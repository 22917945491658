import React from 'react';

import { PersonItemFragment } from '../../generated/graphql';
import { cleanUserInput } from '../../lib/cleanUserInput';
import { extractParamsFromMediaUrl } from '../../lib/mediaApiUrl';
import styled from '../../lib/styled';
import { themeFont } from '../../lib/themeFont';
import { BaseItemProps } from '../PageContents';
import Picture from '../Picture';

const PartyMember = styled.div`
  padding: 44px 25px 0;
  text-align: center;
  ${(props) => props.theme.components.WeddingPartyPerson}

  max-width: 300px;
  width: 300px;
`;
const MemberName = styled.div`
  ${themeFont('s6')}
  font-weight: bolder;
  margin-bottom: 12px;
`;
const ImgCustom = styled.img`
  background-color: rgb(151, 151, 151);
  border: 1px solid rgb(151, 151, 151);
  border-radius: 150px;
  height: 150px;
  margin: 0 auto 16px;
  width: 150px;
`;
const MemberDescription = styled.div`
  ${themeFont('s6')}
  line-height: 1.5;
`;

const Person: React.FC<BaseItemProps> = ({ item, className }) => {
  const { description, id, name, role, photo } = item as PersonItemFragment;
  const mediaUrl = photo?.mediaUrl || '';
  let photoParams;
  if (mediaUrl) photoParams = extractParamsFromMediaUrl(mediaUrl);
  if (!name) {
    return null;
  }
  return (
    <PartyMember key={id} className={className} data-testid={`person-item-${id}`}>
      {photoParams && (
        <Picture
          ImgCustom={ImgCustom}
          url={photoParams.url}
          crop={photoParams.crop}
          alt={name}
          lazyLoad={false}
          sm={150}
          md={150}
          lg={150}
          xl={150}
        />
      )}
      <MemberName>
        {name}
        {role && ` - ${role}`}
      </MemberName>
      {description && (
        <MemberDescription
          dangerouslySetInnerHTML={{
            __html: cleanUserInput(description),
          }}
        />
      )}
    </PartyMember>
  );
};
export default Person;
