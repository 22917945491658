import React, { MouseEvent } from 'react';

import mediaApiUrl, { extractParamsFromMediaUrl } from '../lib/mediaApiUrl';
import styled from '../lib/styled';
import useLazyLoading from '../lib/useLazyLoading';

const Img = styled.img`
  width: 100%;
  &[loading='lazy']:not(.loaded) {
    filter: blur(5px);
  }
`;

interface PictureProps {
  alt: string;
  url?: string;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  responsiveImages?: {
    smUrl?: string;
    mdUrl?: string;
    lgUrl?: string;
    xlUrl?: string;
  };
  className?: string;
  crop?: string;
  smartCrop?: { width: number; height: number };
  ImgCustom?: any;
  lazyLoad?: boolean;
  placeholderWidth?: number;
  rotation?: number | null;
  onClick?(event: MouseEvent<HTMLImageElement>): void;
  'data-testid'?: string;
}
const Picture: React.FC<PictureProps> = React.forwardRef<HTMLPictureElement, PictureProps>(
  (
    {
      url: fullUrl,
      alt,
      sm = 600,
      md = 768,
      lg = 1024,
      xl = 1280,
      responsiveImages = {},
      crop,
      smartCrop,
      lazyLoad = true,
      ImgCustom,
      placeholderWidth = Math.round(sm / 7),
      rotation,
      ...rest
    },
    ref
  ) => {
    // sm: > 0px
    // md: > 768px
    // lg: > 1024px
    // xl: > 1280px
    // + @media selectors for 1.5x and 2x pixel density devices
    useLazyLoading();

    if (!fullUrl) {
      return null;
    }
    const { url, format } = extractParamsFromMediaUrl(fullUrl);

    const { smUrl = url, mdUrl = url, lgUrl = url, xlUrl = url } = responsiveImages;

    const ImgComponent = ImgCustom || Img;

    if (!url || url.length === 0) {
      return null;
    }
    if (!fullUrl.match(/xogrp.com/)) {
      return <img alt={alt} src={fullUrl} {...rest} />;
    }

    let defaultSmartCrop = '';

    if (smartCrop) {
      defaultSmartCrop = `${smartCrop?.width}.${smartCrop?.height}`;
    }

    let srcsetProp;
    if (lazyLoad) {
      srcsetProp = 'data-srcset';
    } else {
      srcsetProp = 'srcSet';
    }
    let imgOptions;
    if (lazyLoad) {
      imgOptions = {
        loading: 'lazy',
        'data-src': mediaApiUrl({ crop, format, rotation, url, width: md }),
        src: mediaApiUrl({
          crop,
          format,
          smartCrop: defaultSmartCrop,
          rotation,
          url,
          width: placeholderWidth,
        }),
      };
    } else {
      imgOptions = {
        src: mediaApiUrl({
          crop,
          format,
          smartCrop: defaultSmartCrop,
          rotation,
          url,
          width: md,
        }),
      };
    }

    const smartCrop2x = smartCrop && `${smartCrop.width * 2}.${smartCrop.height * 2}`;
    const smartCrop15x = smartCrop && `${smartCrop.width * 1.5}.${smartCrop.height * 1.5}`;

    return (
      <picture {...rest} ref={ref}>
        {/* >2x pixel density */}
        <source
          media="(min-resolution: 192dpi) and (min-width: 1280px),
          (-webkit-min-device-pixel-ratio: 2) and (min-width: 1280px)"
          {...{
            [srcsetProp]: mediaApiUrl({
              crop,
              smartCrop: smartCrop2x,
              format,
              rotation,
              url: xlUrl,
              width: xl * 2,
            }),
          }}
        />
        <source
          media="(min-resolution: 192dpi) and (min-width: 1024px),
          (-webkit-min-device-pixel-ratio: 2) and (min-width: 1024px)"
          {...{
            [srcsetProp]: mediaApiUrl({
              crop,
              smartCrop: smartCrop2x,
              format,
              rotation,
              url: lgUrl,
              width: lg * 2,
            }),
          }}
        />
        <source
          media="(min-resolution: 192dpi) and (min-width: 768px),
          (-webkit-min-device-pixel-ratio: 2) and (min-width: 768px)"
          {...{
            [srcsetProp]: mediaApiUrl({
              crop,
              smartCrop: smartCrop2x,
              format,
              rotation,
              url: mdUrl,
              width: md * 2,
            }),
          }}
        />
        <source
          media="(min-resolution: 192dpi), (-webkit-min-device-pixel-ratio: 2)"
          {...{
            [srcsetProp]: mediaApiUrl({
              crop,
              smartCrop: smartCrop2x,
              format,
              rotation,
              url: smUrl,
              width: sm * 2,
            }),
          }}
        />

        {/* >1.5x pixel density */}
        <source
          media="(min-resolution: 144dpi) and (min-width: 1280px),
          (-webkit-min-device-pixel-ratio: 1.5) and (min-width: 1280px)"
          {...{
            [srcsetProp]: mediaApiUrl({
              crop,
              smartCrop: smartCrop15x,
              format,
              rotation,
              url: xlUrl,
              width: xl * 1.5,
            }),
          }}
        />
        <source
          media="(min-resolution: 144dpi) and (min-width: 1024px),
          (-webkit-min-device-pixel-ratio: 1.5) and (min-width: 1024px)"
          {...{
            [srcsetProp]: mediaApiUrl({
              crop,
              smartCrop: smartCrop15x,
              format,
              rotation,
              url: lgUrl,
              width: lg * 1.5,
            }),
          }}
        />
        <source
          media="(min-resolution: 144dpi) and (min-width: 768px),
        (-webkit-min-device-pixel-ratio: 1.5) and (min-width: 768px)"
          {...{
            [srcsetProp]: mediaApiUrl({
              crop,
              smartCrop: smartCrop15x,
              format,
              rotation,
              url: mdUrl,
              width: md * 1.5,
            }),
          }}
        />
        <source
          media="(min-resolution: 144dpi), (-webkit-min-device-pixel-ratio: 1.5)"
          {...{
            [srcsetProp]: mediaApiUrl({
              crop,
              smartCrop: smartCrop15x,
              format,
              rotation,
              url: smUrl,
              width: sm * 1.5,
            }),
          }}
        />

        {/* any pixel density (1x) */}
        <source
          media="(min-width: 1280px)"
          {...{
            [srcsetProp]: mediaApiUrl({
              crop,
              smartCrop: defaultSmartCrop,
              format,
              rotation,
              url: xlUrl,
              width: xl,
            }),
          }}
        />
        <source
          media="(min-width: 1024px)"
          {...{
            [srcsetProp]: mediaApiUrl({
              crop,
              smartCrop: defaultSmartCrop,
              format,
              rotation,
              url: lgUrl,
              width: lg,
            }),
          }}
        />
        <source
          media="(min-width: 768px)"
          {...{
            [srcsetProp]: mediaApiUrl({
              crop,
              smartCrop: defaultSmartCrop,
              format,
              rotation,
              url: mdUrl,
              width: md,
            }),
          }}
        />
        <source
          {...{
            [srcsetProp]: mediaApiUrl({
              crop,
              smartCrop: defaultSmartCrop,
              format,
              rotation,
              url: smUrl,
              width: sm,
            }),
          }}
        />

        {/* Fall-back */}
        <ImgComponent alt={alt} {...imgOptions} />
      </picture>
    );
  }
);

export default Picture;
