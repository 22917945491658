import { Theme } from './styled';

type FontTypes = 's1' | 's2' | 's3' | 's4' | 's5' | 's6' | 'tiny';
interface ThemeFontProps {
  theme: Theme;
}

const mobileFontMultiplier = {
  s1: 0.75, // 60px -> 42px (typical values)
  s2: 0.75, // 42px -> 32px
  s3: 0.75, // 32px -> 24px
  s4: 0.75, // 24px -> 18px
  s5: 0.75, // 20px -> 15px
  s6: 0.875, // 16px -> 14px
  tiny: 0.8333, // 12px -> 10px
};
const getMultiplier = (font: FontTypes) => mobileFontMultiplier[font] || 0.75;

export const themeFont = (font: FontTypes) => ({ theme }: ThemeFontProps) => {
  return {
    '@media (max-width: 640px)': {
      fontSize: `calc(${theme.fonts[font].fontSize} * ${getMultiplier(font)})`,
    },
    ...theme.fonts[font],
  };
};
