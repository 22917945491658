import React from 'react';

import { ParagraphItemFragment } from '../../generated/graphql';
import { cleanUserInput } from '../../lib/cleanUserInput';
import styled from '../../lib/styled';
import { themeFont } from '../../lib/themeFont';
import { BaseItemProps } from '../PageContents';

export const P = styled.div`
  padding: 0;
  margin: 1.1em auto;
  max-width: 550px;
  ${themeFont('s6')}

  @media (max-width: 768px) {
    padding: 0 16px;
  }

  strong {
    font-weight: bolder !important;
  }
  em {
    font-style: italic !important;
  }
  a {
    text-decoration: underline;
    ${(props) => props.theme.components.Link};
  }
  .ql-align-right {
    text-align: right;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-left {
    text-align: left;
  }
  .ql-align-justify {
    text-align: justify;
  }
`;

const ParagraphItem: React.FC<BaseItemProps> = ({ item, className }) => {
  const { content, id } = item as ParagraphItemFragment;

  return (
    <P
      className={className}
      dangerouslySetInnerHTML={{ __html: cleanUserInput(content) }}
      data-testid={`paragraph-item-${id}`}
    />
  );
};

export default ParagraphItem;
