import React, { useContext } from 'react';

import styled from '../lib/styled';
import { themeFont } from '../lib/themeFont';
import useDateDisplayWithFormat, { SPECIFY_FORMAT } from '../lib/useDateDisplayWithFormat';
import { WWContext } from './WWContext';

const StylishWrap = styled.div`
  letter-spacing: 2.23px;
  line-height: 1.3;
  ${themeFont('s2')}

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 12px auto;
  padding: 0 16px;
  width: fit-content;

  @media (min-width: 640px) {
    flex-direction: row;
    margin: 52px auto 20px;
  }
  ${({ theme }) => theme.components.DateLocationLockup}
`;
const DateDiv = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.components.DateLocationLockup?.borderColor || theme.fonts.s4.color};
  padding: 0 36px 20px;

  @media (min-width: 640px) {
    border-bottom: none;
    border-right: 1px solid ${({ theme }) => theme.components.DateLocationLockup?.borderColor || theme.fonts.s4.color};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 72px 20px 0;
    width: 315px;
  }
`;
const Location = styled.div`
  padding: 20px 36px 0;

  @media (min-width: 640px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0 20px 72px;
    width: 315px;
  }
`;
const Detail = styled.div`
  text-align: center;
`;

const DateLocationLockup = () => {
  const { data } = useContext(WWContext);

  const { weddingLocation, hideDate } = data.weddingWebsiteResult;
  if (!weddingLocation) return null;

  const formattedDate = useDateDisplayWithFormat(SPECIFY_FORMAT.NATURAL);
  if (!formattedDate) return null;
  const [city, state] = weddingLocation.split(',');

  return (
    <StylishWrap>
      <DateDiv>
        <Detail>
          <span>{hideDate ? 'Date TBA' : formattedDate}</span>
        </Detail>
      </DateDiv>
      <Location>
        <Detail>
          {city}
          <br />
          {state}
        </Detail>
      </Location>
    </StylishWrap>
  );
};
export default DateLocationLockup;
