import React from 'react'
import SVGBase, { SVGComponentProps } from './SVGBase'

const ArrowLeft = (props: SVGComponentProps) => {
  const { className, title = 'Arrow Left' } = props
  return (
    <SVGBase
      className={className}
      labeledBy="arrow-left-svg"
      path={<path d="M19.333 22.667l-6.667-6.667 6.667-6.667z" />}
      title={title}
    />
  )
}
export default ArrowLeft
