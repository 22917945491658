import { withTheme } from 'emotion-theming'
import { useEffect } from 'react'

import googleFontQuery from './googleFontQuery'

const GoogleFonts = withTheme(props => {
  const query = googleFontQuery(props.theme.fonts)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line
      const WebFont = require('webfontloader')

      // If we don't check for this then the site will crash
      if (query && query.length) {
        WebFont.load({ google: { families: query } })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
})
export default GoogleFonts
