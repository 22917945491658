import React from 'react';
import styled from '../../../../lib/styled';
import { useNavLink } from '../../../../lib/useNavLinks';
import { MobileNavAndButton } from '../Mobile';
import DesktopLiteNav from './Desktop';

const defaultColors = {
  mobileNav: '#ffffff',
};

const MobileNavWrap = styled.span`
  > div {
    overflow: visible;
    width: 123px;
    height: 48px;

    > button {
      height: 100%;
      width: 100%;
      border-radius: 4px;
      background: ${defaultColors.mobileNav};
      ${({ theme }) => theme.liteSite?.Nav?.Button}

      span::before,
      span::after {
        margin-left: -0.25rem;
      }
    }
  }
`;

const LiteNav = () => {
  const pages = useNavLink();
  return (
    <>
      <DesktopLiteNav />
      <MobileNavWrap>
        <MobileNavAndButton pages={pages} showMenuText />
      </MobileNavWrap>
    </>
  );
};

export default LiteNav;
