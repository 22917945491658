import snakeCase from 'lodash/snakeCase';
import React from 'react';

import { BasicItemFragment, StoryItemFragment } from '../../generated/graphql';
import { cleanUserInput } from '../../lib/cleanUserInput';
import { BaseItemProps } from '../PageContents';
import { Item, ItemDescription, ItemPicture, Name } from './BusinessItem';

type ContentItem = StoryItemFragment | BasicItemFragment;

const ContentItem: React.FC<BaseItemProps> = ({ item, className }) => {
  const { id, type, title, description, photo } = item as ContentItem;
  const mediaUrl = photo?.mediaUrl || '';

  let baseMediaUrl;
  if (mediaUrl) {
    const transformIndex = mediaUrl.indexOf('~');
    if (transformIndex !== -1) {
      baseMediaUrl = mediaUrl.slice(0, transformIndex);
    } else {
      baseMediaUrl = mediaUrl;
    }
  }

  return (
    <Item className={className} data-testid={`${snakeCase(type).replace('_', '-')}-${id}`}>
      {title && <Name>{title}</Name>}
      {baseMediaUrl && (
        <ItemPicture url={baseMediaUrl} sm={360} md={510} lg={510} xl={510} alt={`Picture of ${title}`} />
      )}

      {description && (
        <ItemDescription
          dangerouslySetInnerHTML={{
            __html: cleanUserInput(description),
          }}
        />
      )}
    </Item>
  );
};

export default ContentItem;
