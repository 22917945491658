import React from 'react';

import { SubEventFragment } from '../../../generated/graphql';
import { colorAlpha } from '../../../lib/colorAlpha';
import getEventDateTime from '../../../lib/getEventDateTime';
import styled from '../../../lib/styled';
import { themeFont } from '../../../lib/themeFont';

const SubEvent = styled.div`
  display: flex;
  max-width: 720px;
  border-bottom: 1px solid ${(props) => colorAlpha(props.theme.fonts.s6.color, '15%')};
  ${(props) => props.theme.components.SubEvent}
`;
interface BlockProps {
  hasTime: boolean;
}
const Block = styled.div<BlockProps>`
  overflow-wrap: break-word;
  padding: 12px 0;
  text-align: left;
  width: 100%;
  @media (min-width: 720px) {
    ${(props) => !props.hasTime && 'text-align: center;'}
    padding: 32px 16px;
  }
`;
const TimeBlock = styled(Block)`
  display: none;
  @media (min-width: 720px) {
    display: block;
    min-width: 310px;
    width: 310px;
    text-align: end;
  }
`;
const Name = styled.h4`
  ${themeFont('s4')}
  margin: 12px 0;
  line-height: 1;
`;
const Detail = styled.div`
  ${themeFont('s6')}
  margin: 12px 0;
`;
const Field = styled.div`
  margin: 2px 0;
`;
const Notes = styled.div`
  ${themeFont('s6')}
  margin: 20px 0 12px;
  white-space: pre-line;
`;
interface TimeRangeProps {
  className?: string;
  formattedTime?: string | null;
  formattedEndTime?: string | null;
}
const TimeRange = ({ className, formattedTime, formattedEndTime }: TimeRangeProps) => (
  <Name className={className}>
    {formattedTime}
    {formattedTime && formattedEndTime && `–${formattedEndTime}`}
    {!formattedTime && formattedEndTime && `Ends at ${formattedEndTime}`}
  </Name>
);
const MobileTimeRange = styled(TimeRange)`
  ${themeFont('s5')}
  margin: 16px 0;
  @media (min-width: 720px) {
    display: none;
  }
`;

const ScheduleSubEvent: React.FC<SubEventFragment> = ({
  attire,
  endTime,
  id,
  location,
  name,
  notes,
  time,
  visible,
}) => {
  const { name: venueName, fullAddress } = location || {};
  const { time: formattedTime = '', endTime: formattedEndTime = '' } = getEventDateTime({
    time,
    endTime,
  });
  const hasTime = !!(formattedTime || formattedEndTime);

  if (!visible) {
    return null;
  }

  return (
    <SubEvent key={id} data-testid={`event-${id}`}>
      {hasTime && (
        <TimeBlock hasTime={hasTime}>
          <TimeRange formattedTime={formattedTime} formattedEndTime={formattedEndTime} />
        </TimeBlock>
      )}
      <Block hasTime={hasTime}>
        <MobileTimeRange formattedTime={formattedTime} formattedEndTime={formattedEndTime} />
        <Name>{name}</Name>
        {(venueName || fullAddress) && (
          <Detail>
            <Field>{venueName}</Field>
            <Field>{fullAddress}</Field>
          </Detail>
        )}
        {attire && <Detail>{`Attire: ${attire}`}</Detail>}
        {notes && <Notes>{notes}</Notes>}
      </Block>
    </SubEvent>
  );
};
export default ScheduleSubEvent;
