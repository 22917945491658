import { ThemeProvider } from 'emotion-theming';
import { useRouter } from 'next/router';
import React, { useContext, useMemo } from 'react';

import useThemeStyles from '../../lib/useThemeStyles';
import { isClient } from '../../lib/utils';
import Layout from '../Layout';
import LazyLoadImages from '../LazyLoadImages';
import PageContents from '../PageContents';
import { WWContext } from '../WWContext';
import RegistryTemplate from './RegistryTemplate';

export interface TemplateProps {
  themeOverride: string;
}

const BaseTemplate: React.FC<TemplateProps> = ({ themeOverride }) => {
  const {
    replace,
    query: { route, pathPrefix, slug, preview = 'true' },
  } = useRouter();
  const { data } = useContext(WWContext);
  const { pages } = data.weddingWebsiteResult;
  const routeName = Array.isArray(route) ? route[0] : !route ? '' : route;
  const currentPage = useMemo(() => pages?.find((p) => p.routeName === routeName), [pages, routeName]);
  const themeStyles = useThemeStyles(themeOverride);

  // If any page besides page with routeName = / is hidden redirect
  if (!currentPage || (currentPage && currentPage.routeName !== '' && !preview && !currentPage.show)) {
    // Next/Router can only be used client side, do the routing client side
    if (isClient()) {
      replace(`/${pathPrefix}/${slug}`);
      return null;
    } else {
      return null;
    }
  }

  return (
    <ThemeProvider theme={themeStyles}>
      <LazyLoadImages />
      <Layout>
        {currentPage.type === 'RegistryPage' ? (
          <RegistryTemplate />
        ) : (
          <PageContents page={currentPage} photoAlbums={data.weddingWebsiteResult.photoAlbums} />
        )}
      </Layout>
    </ThemeProvider>
  );
};

export default BaseTemplate;
