import React, { Fragment, useEffect } from 'react';

import { PhotoAlbumsFragment, WwsPagesFragment } from '../generated/graphql';
import styled from '../lib/styled';
import { useCurrentRoute } from '../lib/useCurrentRoute';
import useTracking from '../lib/useTracking';
import DateLocationLockup from './DateLocationLockup';
import MobileHomePageNav from './MobileHomePageNav';
import BusinessItem from './PageItems/BusinessItem';
import ContentItem from './PageItems/ContentItem';
import EmbedItem from './PageItems/EmbedItem';
import EventGroupItem from './PageItems/EventGroupItem';
import HeadlineItem, { H3 } from './PageItems/HeadlineItem';
import LivestreamItem from './PageItems/LivestreamItem';
import ParagraphItem from './PageItems/ParagraphItem';
import PersonItem from './PageItems/PersonItem';
import PhotoAlbum from './PageItems/PhotoAlbum';
import PhotoGalleryItem from './PageItems/PhotoGalleryItem';
import PhotoItem from './PageItems/PhotoItem';
import PhotoTimelineItem from './PageItems/PhotoTimelineItem';
import QuestionItem from './PageItems/QuestionItem';

interface PageContentsProps {
  page: NonNullable<WwsPagesFragment['pages']>[0];
  photoAlbums: PhotoAlbumsFragment['photoAlbums'];
}

export interface BaseItemProps {
  className?: string;
  item: NonNullable<WwsPagesFragment['pages']>[0]['items'][0];
}

const WeddingPageContainer = styled.div`
  max-width: 605px;
  margin: 0 auto;
`;

const Grid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  & > *:not(.PersonItem) {
    flex-basis: 100%;
  }
`;

const PageContents: React.FC<PageContentsProps> = ({ page, photoAlbums }) => {
  const currentRoute = useCurrentRoute();
  const track = useTracking('visit', currentRoute);
  const isHomePage = page.routeName === '';

  const RootEl = page.type === 'WeddingPartyPage' ? WeddingPageContainer : Fragment;
  const GridEl = page.type === 'WeddingPartyPage' ? Grid : Fragment;

  useEffect(() => {
    track('guest visit');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (page.items.length || (page.type === 'PhotoPage' && photoAlbums?.length)) {
    return (
      <RootEl>
        <GridEl>
          {page.type === 'PhotoPage' && <PhotoAlbum photoAlbums={photoAlbums} />}
          {page.type === 'WeddingPage' && <DateLocationLockup />}
          {page.items.map((item) => {
            let Component;

            if (item.__typename === 'WWS_HeadlineItem') {
              Component = HeadlineItem;
            } else if (item.__typename === 'WWS_ParagraphItem') {
              Component = ParagraphItem;
            } else if (item.__typename === 'WWS_PersonItem') {
              Component = PersonItem;
            } else if (item.__typename === 'WWS_PhotoItem' || item.__typename === 'WWS_GifItem') {
              Component = PhotoItem;
            } else if (item.__typename === 'WWS_QuestionItem') {
              Component = QuestionItem;
            } else if (item.__typename === 'WWS_LivestreamItem') {
              Component = LivestreamItem;
            } else if (item.__typename === 'WWS_EmbedItem') {
              Component = EmbedItem;
            } else if (item.__typename === 'WWS_PhotoGalleryItem') {
              Component = PhotoGalleryItem;
            } else if (item.__typename === 'WWS_PhotoTimelineItem') {
              Component = PhotoTimelineItem;
            } else if (
              ['WWS_AccommodationItem', 'WWS_ActivityItem', 'WWS_TransportationItem'].includes(item.__typename || '')
            ) {
              Component = BusinessItem;
            } else if (['WWS_StoryItem', 'WWS_BasicItem'].includes(item.__typename || '')) {
              Component = ContentItem;
            } else if (item.__typename === 'WWS_EventGroupItem') {
              Component = EventGroupItem;
            } else {
              return null;
            }

            return <Component key={item.id} item={item} className={item.type} />;
          })}
          {page.type === 'WeddingPage' && <MobileHomePageNav />}
        </GridEl>
      </RootEl>
    );
  }

  if (isHomePage) {
    return null;
  }

  const placeholdText = `${page.title} coming soon!`;
  return <H3>{placeholdText}</H3>;
};
export default PageContents;
