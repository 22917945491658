interface MediaApiUrlProps {
  crop?: string;
  height?: number;
  format?: string;
  rotation?: number | null;
  smartCrop?: string;
  url: string;
  width?: number;
}

export const extractParamsFromMediaUrl = (mediaUrl: string) => {
  const url = mediaUrl.split(/(~|\.[a-zA-Z0-9]{3,4}$)/)[0];
  const match = mediaUrl.match(/cr_(\d+\.\d+\.\d+\.\d+)/);
  const crop = (match && match[1]) || undefined;
  const formatMatch = mediaUrl.match(/\.([a-zA-Z0-9]{3,4})$/);
  const format = (formatMatch && formatMatch[1]) || undefined;

  return { url, crop, format };
};

export const mediaApiUrl = (props: MediaApiUrlProps) => {
  const { crop, format, height, rotation, smartCrop, url, width } = props;

  const fileExt = format ? `.${format}` : '';

  const transforms = [];

  if (rotation) {
    transforms.push(`rt_${rotation}`);
  } else {
    transforms.push('rt_auto');
  }

  if (smartCrop) {
    transforms.push(`sc_${smartCrop}`);
  }

  if (!smartCrop) {
    if (crop) {
      transforms.push(`cr_${crop}`);
    }
    if (width && !height) {
      transforms.push(`rs_${width}.h`);
    } else if (height && !width) {
      transforms.push(`rs_w.${height}`);
    } else if (height && width) {
      transforms.push(`rs_${width}.${height}`);
    }
  }

  return `${url}~${transforms.join('-')}${fileExt}?ordering=explicit`;
};
export default mediaApiUrl;
