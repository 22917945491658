import { useContext } from 'react';

import { WWContext } from '../components/WWContext';
import expandFonts from './expandFonts';

const useThemeStyles = (themeOverride?: string) => {
  const { data } = useContext(WWContext);
  const { theme } = data.weddingWebsiteResult;

  let themeStyles;
  if (themeOverride) {
    try {
      themeStyles = JSON.parse(themeOverride);
    } catch (e) {
      themeStyles = theme!.themeStyles && theme!.themeStyles[0]?.styles;
    }
  } else if (theme) {
    themeStyles = theme.themeStyles && theme.themeStyles[0]?.styles;
  }
  if (!themeStyles) return {};
  themeStyles = expandFonts(themeStyles);

  return themeStyles;
};

export default useThemeStyles;
