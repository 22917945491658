import { useEffect } from 'react';

const useLazyLoading = () => {
  useEffect(() => {
    if ((document as any).lazyLoadInstance) {
      (document as any).lazyLoadInstance.update();
    }
  }, []);
};
export default useLazyLoading;
