import { useRouter } from 'next/router';
import React from 'react';
import bp from '../../../../lib/cssMediaQuery';
import styled from '../../../../lib/styled';
import { useCurrentRoute } from '../../../../lib/useCurrentRoute';
import { useNavLink } from '../../../../lib/useNavLinks';
import useTracking from '../../../../lib/useTracking';
import NavLink from '../NavLink';

const defaultColors = {
  background: '#414042',
  nav: '#f7f7f7',
};

const DesktopNav = styled.nav`
  display: none;
  ${bp.min768} {
    display: block;
    width: 100%;
  }
`;

const NavList = styled.ul`
  li {
    list-style: none;
    margin-right: 1.5rem;
  }

  display: flex;
  width: 100%;
  max-height: 70px;
  justify-content: flex-end;
  background: ${defaultColors.background};
  ${({ theme }) => theme.liteSite?.Nav?.Background}
`;

interface LiteNavItemProps {
  active: boolean;
}

const LiteNavItem = styled.li<LiteNavItemProps>`
  span {
    height: 3rem;
    color: ${defaultColors.nav};
    box-sizing: border-box;
    ${({ active, theme }) =>
      active &&
      `
    border-bottom: 5px solid ${theme.liteSite?.Nav?.Link?.color || defaultColors.nav};
  `}
    ${({ theme }) => `
      ${bp.min768} {
        :hover {
          border-bottom: 5px solid ${theme.liteSite?.Nav?.Link?.color || defaultColors.nav};
        }
      }
    `};
    ${({ theme }) => theme.liteSite?.Nav?.Link}
    ${({ active, theme }) => active && theme.components.Nav?.LinkActive}
  }
`;

const LiteNavRsvpItem = styled.li`
  // for RSVP nav link
  a {
    margin-top: 3px;
  }
  span {
    height: initial;
    padding: 0.75rem 1.25rem;
    color: ${defaultColors.nav};
    border: 1px solid ${defaultColors.nav};
    ${({ theme }) => theme.liteSite?.Nav?.Link}
  }
`;

const DesktopLiteNav = () => {
  const pages = useNavLink();
  const { query } = useRouter();
  const currentRoute = useCurrentRoute();

  const userDecisionArea = 'desktop nav';
  const trackGuest = useTracking('guest', currentRoute, userDecisionArea);
  const trackRsvp = useTracking('rsvp', currentRoute, userDecisionArea);

  const handleTracking = (selection: string) => {
    if (selection === 'rsvp') {
      trackRsvp(selection, '');
    }

    trackGuest(selection, '');
  };

  return (
    <DesktopNav>
      <NavList>
        {pages?.map((page) => {
          const NavLinkWrap = page.type === 'RsvpNavItem' && page.routeName === 'rsvp' ? LiteNavRsvpItem : LiteNavItem;

          return (
            <NavLinkWrap active={currentRoute === page.routeName}>
              <NavLink
                href={{
                  pathname: '/[pathPrefix]/[slug]/[[route]]',
                  query: {
                    ...query,
                    route: page.routeName,
                  },
                }}
                route={page.routeName as string}
                title={page.title as string}
                handleTracking={handleTracking}
              />
            </NavLinkWrap>
          );
        })}
      </NavList>
    </DesktopNav>
  );
};

export default DesktopLiteNav;
