import Link, { LinkProps } from 'next/link';
import React from 'react';

import { colorAlpha } from '../../../lib/colorAlpha';
import styled from '../../../lib/styled';
import { themeFont } from '../../../lib/themeFont';
import { useCurrentRoute } from '../../../lib/useCurrentRoute';

interface AnchorProps {
  onClick(): void;
}
interface LinkTitleProps {
  active: boolean;
  desktop?: boolean;
  theme?: any;
}
export const Anchor = styled.a<AnchorProps>`
  display: block;
  padding: 14px 0;
  margin: 8px 0;
`;
const LinkTitle = styled.span<LinkTitleProps>`
  ${themeFont('s6')}
  border-bottom: 2px solid transparent;
  display: block;
  letter-spacing: 2px;
  line-height: 1;
  margin: 0 auto;
  padding: 8px 8px 6px;
  text-decoration: none;
  transition: all 0.25s;
  width: fit-content;
  ${({ active, desktop, theme }) =>
    desktop &&
    !active &&
    `
    :hover {
      color: ${theme.colors.primaryHover};
      border-bottom: 2px solid ${colorAlpha(theme.colors.primaryHover, '70%')};
    }
  `}
  ${({ active, theme }) =>
    active &&
    `
    border-bottom: 2px solid ${theme.fonts.s5.color};
  `}
  ${(props) => props.theme.components.Nav?.Link}
  ${({ active, theme }) => active && theme.components.Nav?.LinkActive}
`;

interface NavLinkProps {
  className?: string;
  desktop?: boolean;
  handleTracking: (seletion: string) => void;
  setMobileNavOpen?: (open: boolean) => void;
  title: string;
  route: string;
  href: LinkProps['href'];
}
const NavLink: React.FC<NavLinkProps> = ({
  className,
  desktop,
  setMobileNavOpen = () => {},
  handleTracking,
  href,
  title,
  route,
}) => {
  const currentRoute = useCurrentRoute();
  const active = currentRoute === route;

  const noFollow = !['', 'registry'].includes(route);

  const onClick = () => {
    setMobileNavOpen(false);
    handleTracking(route);
  };

  return (
    <Link href={href} passHref>
      <Anchor rel={noFollow ? 'nofollow' : undefined} className={className} onClick={onClick}>
        <LinkTitle active={active} desktop={desktop}>
          {title}
        </LinkTitle>
      </Anchor>
    </Link>
  );
};

export default NavLink;
