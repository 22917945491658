import { Maybe } from '../generated/graphql';

interface GetInitialsProps {
  firstName?: Maybe<string>;
  fianceFirstName?: Maybe<string>;
}

export const getInitials = (props: GetInitialsProps) => {
  const { firstName, fianceFirstName } = props;
  if (!firstName || !fianceFirstName) {
    return null;
  }
  const initial = firstName.charAt(0).toUpperCase();
  const fianceInitial = fianceFirstName.charAt(0).toUpperCase();

  return `${initial}&${fianceInitial}`;
};
