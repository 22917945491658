import React from 'react';

import { EmbedItemFragment } from '../../generated/graphql';
import styled from '../../lib/styled';
import { BaseItemProps } from '../PageContents';
import { Item, ItemDescription } from './BusinessItem';
import { H3 } from './HeadlineItem';

const Iframe = styled.div`
  display: flex;
  justify-content: center;

  div {
    width: 100%;
  }
`;

const EmbedItem: React.FC<BaseItemProps> = ({ item, className }) => {
  const { id, title, description, embededCode } = item as EmbedItemFragment;

  return (
    <Item className={className} data-testid={`embed-item-${id}`}>
      {title && <H3>{title}</H3>}
      {description && <ItemDescription>{description}</ItemDescription>}

      <Iframe
        dangerouslySetInnerHTML={{
          __html: embededCode,
        }}
      />
    </Item>
  );
};

export default EmbedItem;
