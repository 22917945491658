import React from 'react';

import { AnnouncementFragment } from '../../generated/graphql';
import styled from '../../lib/styled';
import zIndices from '../../lib/zIndices';

interface Props {
  announcement?: AnnouncementFragment;
  className?: string;
}

const Bar = styled.div`
  align-items: center;
  border: 12px solid ${(props) => props.theme.colors.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 20px;
  width: 100%;
  z-index: ${zIndices.header};

  @media (max-width: 768px) {
    border: 10px solid ${(props) => props.theme.colors.background};
    border-left: none;
    border-right: none;
    padding: 26px 20px;
  }

  background-color: ${(props) => props.theme.fonts.s6.color};
`;
const Message = styled('p')`
  margin: 0 auto;
  max-width: 700px;
  text-align: center;

  ${(props) => props.theme.fonts.s6};
  color: ${(props) => props.theme.colors.background};
`;

const Announcement: React.FC<Props> = ({ announcement, className }) => {
  if (!announcement || !announcement.show) return null;
  const { message } = announcement;

  return (
    <Bar className={className}>
      <Message>{message}</Message>
    </Bar>
  );
};

export default Announcement;
