import { useContext, useMemo } from 'react';

import { WWContext } from '../components/WWContext';

const LITE_SITE_TYPE = 'LiteSitePage';

export const useIsLiteSite = (): boolean => {
  const { data } = useContext(WWContext);

  return !!useMemo(() => data.weddingWebsiteResult.pages?.find((p) => p.type === LITE_SITE_TYPE), [data]);
};
