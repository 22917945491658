import React from 'react'

interface Props {
  time?: string | null
  endTime?: string | null
}

const EventTime = ({ time, endTime }: Props) => {
  if (!time) {
    return null
  }

  return <>{`${time}${endTime ? `–${endTime}` : ''}`}</>
}

export default EventTime
