import React, { useState } from 'react';

import { WeddingWebsiteQuery, WwsResultFragment } from '../generated/graphql';

interface Context {
  data: WWSDataWithResult;
  setWWData: (wwDate: WWSDataWithResult) => void;
}

export interface WWSDataWithResult extends WeddingWebsiteQuery {
  // This is inside if condition so this is always true
  weddingWebsiteResult: WwsResultFragment;
}

interface Props {
  data: WeddingWebsiteQuery;
}

export const WWContext = React.createContext<Context>({} as Context);

export const WWProvider: React.FC<Props> = ({ children, data }) => {
  const [wwData, setWWData] = useState<WWSDataWithResult>(data as WWSDataWithResult);
  return (
    <WWContext.Provider
      value={{
        data: wwData,
        setWWData,
      }}
    >
      {children}
    </WWContext.Provider>
  );
};
