import { Theme } from './styled';

type FontsKey = keyof Theme['fonts'];

// TODO: confirm if this is needed
const expandFonts = (theme: Theme) => {
  const fontNames = Object.keys(theme.fonts);
  fontNames.forEach((fontName) => {
    const font = theme.fonts[fontName as FontsKey];
    const { base, ...rest } = font;
    if (base) {
      const baseFont = (theme as any)?.baseFonts[base as any];
      // eslint-disable-next-line no-param-reassign
      theme.fonts[fontName as FontsKey] = { ...baseFont, ...rest };
    }
  });
  return theme;
};

export default expandFonts;
