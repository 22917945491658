import React, { useContext } from 'react';
import bp from '../../../lib/cssMediaQuery';

import styled from '../../../lib/styled';
import RSVPButton from '../../RSVPButton';
import { WWContext } from '../../WWContext';
import LiteNav from '../Nav/Lite';

const Background = styled.div`
  background: #c4c4c4;
  color: #646464;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  ${({ theme }) => theme.liteSite?.CardLayout?.components?.Background}
`;

const Card = styled.div`
  background: #f7f7f7;
  width: 400px;
  min-height: 530px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 3rem 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${bp.max768} {
    justify-content: center;
    min-height: 39.5rem;
    width: 70%;
  }
  ${bp.max423} {
    min-height: 23.75rem;
  }
  ${({ theme }) => theme.liteSite?.CardLayout?.components?.Card}
`;

const NameFlourishImg = styled.img`
  width: 100%;
  object-fit: contain;
  height: 200px;
  ${({ theme }) => theme.liteSite?.CardLayout?.components?.NameFlourish}
`;

const DateFlourishImg = styled.img`
  width: 100%;
  object-fit: contain;
  height: 70px;
  margin-top: 3rem;
  ${({ theme }) => theme.liteSite?.CardLayout?.components?.DateFlourish}
`;

const Seperator = styled.div`
  width: 40%;
  border: 1px solid #414042;
  height: 0;
  margin: 3rem 0;
  ${({ theme }) => theme.liteSite?.CardLayout?.components?.Seperator}
`;

const RSVPButtonWrap = styled.span`
  a {
    background: #414042;
    ${bp.max768} {
      margin: 0;
    }
    ${({ theme }) => theme.liteSite?.CardLayout?.components?.Button}
    &:hover {
      background: #414042;
      ${({ theme }) => theme.liteSite?.CardLayout?.components?.Button}
      ${({ theme }) => theme.liteSite?.CardLayout?.components?.ButtonHover}
    }
  }
`;

const CardLayout = () => {
  const { data } = useContext(WWContext);
  const { firstName, fianceFirstName, theme, weddingLocation } = data.weddingWebsiteResult;
  const weddingDate = data.wedding?.weddingDate;
  const hasDateAndLocation = weddingDate && weddingLocation;

  return (
    <>
      <Background>
        <LiteNav />
        <Card>
          <NameFlourishImg
            src={`${process.env.FLOURISH_DOMAIN}/lockup/name?firstName=${firstName}&fianceFirstName=${fianceFirstName}&themeId=${theme?.id}`}
            alt={`${firstName} & ${fianceFirstName}`}
          />
          {hasDateAndLocation && (
            <DateFlourishImg
              src={`${process.env.FLOURISH_DOMAIN}/lockup/dateLocation?date=${weddingDate}&location=${weddingLocation}&themeId=${theme?.id}`}
              alt={`${firstName} & ${fianceFirstName}`}
            />
          )}
          <Seperator />
          <RSVPButtonWrap>
            <RSVPButton />
          </RSVPButtonWrap>
        </Card>
      </Background>
    </>
  );
};

export default CardLayout;
