import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  WWS_Json: any;
  WWS_Date: any;
  GDS__Any: any;
  GDS__FieldSet: any;
};

export type Query = {
  __typename?: 'Query';
  /** Find all themes grouped by family */
  familyThemes: Array<Wws_FamilyThemeType>;
  /** Find single theme by id */
  theme?: Maybe<Wws_Theme>;
  /** Find all new template themes */
  themes: Array<Wws_Theme>;
  /** Find wedding website by slug or vanity */
  weddingWebsiteResult?: Maybe<Wws_WeddingWebsiteResult>;
  event?: Maybe<Gds_Event>;
  events: Array<Gds_Event>;
  household?: Maybe<Gds_Household>;
  households: Array<Gds_Household>;
  householdsWithoutRsvp: Array<Gds_Household>;
  person?: Maybe<Gds_Person>;
  guestWedding?: Maybe<Gds_GuestWedding>;
  group?: Maybe<Gds_Group>;
  groups: Array<Gds_Group>;
  eventRsvpData?: Maybe<Array<Gds_EventRsvpData>>;
  wedding?: Maybe<Wedding>;
};


export type QueryFamilyThemesArgs = {
  variant?: Maybe<Wws_FamilyThemeVariant>;
};


export type QueryThemeArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryWeddingWebsiteResultArgs = {
  slugOrVanity: Scalars['String'];
  pin?: Maybe<Scalars['String']>;
};


export type QueryEventArgs = {
  id: Scalars['String'];
};


export type QueryHouseholdArgs = {
  id: Scalars['String'];
};


export type QueryHouseholdsWithoutRsvpArgs = {
  eventId: Scalars['String'];
};


export type QueryPersonArgs = {
  id: Scalars['String'];
};


export type QueryGroupArgs = {
  id: Scalars['String'];
};

export enum Wws_FamilyThemeVariant {
  /** Data Training */
  Training = 'TRAINING',
  /** Control */
  Random = 'RANDOM',
  /** Lite Site */
  Lite = 'LITE',
  /** Lite Site Control */
  Control = 'CONTROL'
}

export type Wws_FamilyThemeType = {
  __typename?: 'WWS_FamilyThemeType';
  family?: Maybe<Scalars['String']>;
  themes?: Maybe<Array<Wws_Theme>>;
};

export type Wws_Theme = {
  __typename?: 'WWS_Theme';
  active?: Maybe<Scalars['Boolean']>;
  batch?: Maybe<Scalars['Int']>;
  carouselImages?: Maybe<Scalars['WWS_Json']>;
  cssFile?: Maybe<Scalars['String']>;
  designerUrl?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  familyThemes?: Maybe<Array<Wws_Theme>>;
  folderPrefix?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  layoutCoverPhotoRatio?: Maybe<Scalars['String']>;
  layoutId?: Maybe<Scalars['Int']>;
  liteSite?: Maybe<Scalars['Boolean']>;
  mobilePreviewImage?: Maybe<Wws_Photo>;
  mobilePreviewUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  previewImage?: Maybe<Wws_Photo>;
  previewUrl?: Maybe<Scalars['String']>;
  stationaryPreviewImage?: Maybe<Wws_Photo>;
  stationaryPreviewUrl?: Maybe<Scalars['String']>;
  styles?: Maybe<Array<Wws_ThemeStyle>>;
  swatchImage?: Maybe<Wws_Photo>;
  swatchUrl?: Maybe<Scalars['String']>;
  templateVersion?: Maybe<Scalars['Int']>;
  testRank?: Maybe<Scalars['Int']>;
  thankYouUrl?: Maybe<Scalars['String']>;
  themeDesigner?: Maybe<Wws_ThemeDesigner>;
  themeStyles?: Maybe<Scalars['WWS_Json']>;
  version?: Maybe<Scalars['Int']>;
};


export type Wws_Photo = {
  __typename?: 'WWS_Photo';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  caption?: Maybe<Scalars['String']>;
  cropX?: Maybe<Scalars['Int']>;
  cropY?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  mediaApiId: Scalars['String'];
  mediaUrl: Scalars['String'];
  originalMediaUrl: Scalars['String'];
  rotation?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type Wws_AspectRatio = {
  __typename?: 'WWS_AspectRatio';
  height: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['Float'];
  width: Scalars['Int'];
};

export type Wws_ThemeStyle = {
  __typename?: 'WWS_ThemeStyle';
  color?: Maybe<Scalars['String']>;
  element?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  fontStyle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Wws_ThemeDesigner = {
  __typename?: 'WWS_ThemeDesigner';
  companyName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  logo?: Maybe<Scalars['String']>;
  logoHeight?: Maybe<Scalars['Int']>;
  logoWidth?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** WeddingWebsite objects which you can enjoy */
export type Wws_WeddingWebsiteResult = {
  theme?: Maybe<Wws_Theme>;
  weddingUuid?: Maybe<Scalars['String']>;
};

export type Gds_Event = {
  __typename?: 'GDS_Event';
  id: Scalars['String'];
  weddingId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  attire?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  requireRsvp?: Maybe<Scalars['Boolean']>;
  sameVenue?: Maybe<Scalars['Boolean']>;
  visible?: Maybe<Scalars['Boolean']>;
  meals?: Maybe<Array<Scalars['String']>>;
  location?: Maybe<Gds_Location>;
  image?: Maybe<Gds_Image>;
  subEvents?: Maybe<Array<Gds_SubEvent>>;
  questions?: Maybe<Array<Gds_Question>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Gds_Location = {
  __typename?: 'GDS_Location';
  name?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
};

export type Gds_Image = {
  __typename?: 'GDS_Image';
  id: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  rotationAngle?: Maybe<Scalars['Int']>;
  cropBox?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Gds_SubEvent = {
  __typename?: 'GDS_SubEvent';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  attire?: Maybe<Scalars['String']>;
  location?: Maybe<Gds_Location>;
  visible?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Gds_Question = {
  __typename?: 'GDS_Question';
  id: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  answerType?: Maybe<Scalars['String']>;
  answerDestination?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Array<Gds_Option>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Gds_Option = {
  __typename?: 'GDS_Option';
  id: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Gds_Household = {
  __typename?: 'GDS_Household';
  id: Scalars['String'];
  weddingId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  rsvpNote?: Maybe<Scalars['String']>;
  coupleNote?: Maybe<Scalars['String']>;
  people: Array<Gds_Person>;
  address?: Maybe<Gds_Address>;
  answers?: Maybe<Array<Gds_Answer>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Gds_Person = {
  __typename?: 'GDS_Person';
  id: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isLeader?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  invitations: Array<Gds_Invitation>;
  answers?: Maybe<Array<Gds_Answer>>;
  preferences?: Maybe<Gds_Preferences>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Gds_Invitation = {
  __typename?: 'GDS_Invitation';
  id: Scalars['String'];
  eventId: Scalars['String'];
  rsvp?: Maybe<Scalars['Boolean']>;
  meal?: Maybe<Scalars['String']>;
  gift?: Maybe<Scalars['String']>;
  invitationSent?: Maybe<Scalars['Boolean']>;
  thankYouSent?: Maybe<Scalars['Boolean']>;
  answers?: Maybe<Array<Gds_Answer>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Gds_Answer = {
  __typename?: 'GDS_Answer';
  questionId: Scalars['String'];
  optionId?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Gds_Preferences = {
  __typename?: 'GDS_Preferences';
  emailOptOut?: Maybe<Scalars['Boolean']>;
  smsOptOut?: Maybe<Scalars['Boolean']>;
};

export type Gds_Address = {
  __typename?: 'GDS_Address';
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
};

export type Gds_GuestWedding = {
  __typename?: 'GDS_GuestWedding';
  id: Scalars['String'];
  isPrivateRsvp?: Maybe<Scalars['Boolean']>;
  sendRsvpReminder?: Maybe<Scalars['Boolean']>;
  rsvpDeadline?: Maybe<Scalars['String']>;
  rsvpMedium?: Maybe<Scalars['String']>;
  rsvpAsAPage?: Maybe<Scalars['Boolean']>;
  rsvpPageHidden?: Maybe<Scalars['Boolean']>;
  rsvpWorkflow?: Maybe<Scalars['Boolean']>;
  usesSubEvents?: Maybe<Scalars['Boolean']>;
  usesQuestions?: Maybe<Scalars['Boolean']>;
  allowGuestPreview?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Array<Gds_Question>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Gds_Group = {
  __typename?: 'GDS_Group';
  id: Scalars['String'];
  weddingId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  emoji?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Gds_EventRsvpData = {
  __typename?: 'GDS_EventRsvpData';
  eventId: Scalars['String'];
  guestCount?: Maybe<Scalars['Int']>;
  acceptCount?: Maybe<Scalars['Int']>;
  rejectCount?: Maybe<Scalars['Int']>;
};

export type Wedding = {
  __typename?: 'Wedding';
  id: Scalars['ID'];
  /** The wedding date will only be returned if it's confirmed. Format required YYYY-MM-DD */
  weddingDate: Scalars['String'];
  /** The wedding season year, which can be empty. */
  season?: Maybe<Season>;
  /** Unconfirmed Attributes */
  unconfirmedAttributes: Array<Maybe<Scalars['String']>>;
};

export type Season = {
  __typename?: 'Season';
  name?: Maybe<SeasonName>;
  year?: Maybe<Scalars['Int']>;
};

export enum SeasonName {
  Fall = 'Fall',
  Winter = 'Winter',
  Spring = 'Spring',
  Summer = 'Summer'
}

export type Mutation = {
  __typename?: 'Mutation';
  bucketRSVPWorkflow: Gds_GuestWedding;
  createHouseholds: Array<Gds_Household>;
  updateGuestWedding: Gds_GuestWedding;
  updateGuestWeddingRsvpProps: Gds_GuestWedding;
  insertInvitation?: Maybe<Gds_Invitation>;
  updateInvitation?: Maybe<Gds_Invitation>;
  deleteInvitation?: Maybe<Gds_DeleteInvitationPayload>;
  updateGuestWeddingQuestion: Gds_Question;
  addGuestWeddingQuestion: Gds_Question;
  deleteGuestWeddingQuestion: Scalars['String'];
};


export type MutationCreateHouseholdsArgs = {
  input: Array<Gds_CreateHouseholdInput>;
};


export type MutationUpdateGuestWeddingArgs = {
  input: Gds_UpdateGuestWeddingInput;
};


export type MutationUpdateGuestWeddingRsvpPropsArgs = {
  input: Gds_UpdateGuestWeddingRsvpPropsInput;
};


export type MutationInsertInvitationArgs = {
  personId: Scalars['String'];
  householdId: Scalars['String'];
  input: Gds_CreateInvitationInput;
};


export type MutationUpdateInvitationArgs = {
  invitationId: Scalars['String'];
  personId: Scalars['String'];
  householdId: Scalars['String'];
  input: Gds_UpdateInvitationPatchInput;
};


export type MutationDeleteInvitationArgs = {
  input: Gds_DeleteInvitationInput;
};


export type MutationUpdateGuestWeddingQuestionArgs = {
  id: Scalars['String'];
  input: Gds_UpdateQuestionInput;
};


export type MutationAddGuestWeddingQuestionArgs = {
  input: Gds_UpdateQuestionInput;
};


export type MutationDeleteGuestWeddingQuestionArgs = {
  id: Scalars['String'];
};

export type Gds_CreateHouseholdInput = {
  weddingId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  rsvpNote?: Maybe<Scalars['String']>;
  coupleNote?: Maybe<Scalars['String']>;
  people: Array<Gds_CreatePersonInput>;
  address?: Maybe<Gds_CreateAddressInput>;
  answers?: Maybe<Array<Gds_CreateAnswerInput>>;
};

export type Gds_CreatePersonInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isLeader?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  invitations: Array<Gds_CreateInvitationInput>;
  answers?: Maybe<Array<Gds_CreateAnswerInput>>;
};

export type Gds_CreateInvitationInput = {
  eventId: Scalars['String'];
  rsvp?: Maybe<Scalars['Boolean']>;
  meal?: Maybe<Scalars['String']>;
  gift?: Maybe<Scalars['String']>;
  invitationSent?: Maybe<Scalars['Boolean']>;
  thankYouSent?: Maybe<Scalars['Boolean']>;
  answers?: Maybe<Array<Gds_CreateAnswerInput>>;
};

export type Gds_CreateAnswerInput = {
  questionId: Scalars['String'];
  optionId?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Gds_CreateAddressInput = {
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
};

export type Gds_UpdateGuestWeddingInput = {
  isPrivateRsvp?: Maybe<Scalars['Boolean']>;
  sendRsvpReminder?: Maybe<Scalars['Boolean']>;
  rsvpDeadline?: Maybe<Scalars['String']>;
  rsvpMedium?: Maybe<Scalars['String']>;
  rsvpPageHidden?: Maybe<Scalars['Boolean']>;
  allowGuestPreview?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Array<Gds_UpdateQuestionInput>>;
};

export type Gds_UpdateQuestionInput = {
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  answerType?: Maybe<Scalars['String']>;
  answerDestination?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Array<Gds_UpdateOptionInput>>;
};

export type Gds_UpdateOptionInput = {
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type Gds_UpdateGuestWeddingRsvpPropsInput = {
  isPrivateRsvp?: Maybe<Scalars['Boolean']>;
  rsvpDeadline?: Maybe<Scalars['String']>;
  rsvpMedium?: Maybe<Scalars['String']>;
};

export type Gds_UpdateInvitationPatchInput = {
  rsvp?: Maybe<Scalars['Boolean']>;
  gift?: Maybe<Scalars['String']>;
  invitationSent?: Maybe<Scalars['Boolean']>;
  thankYouSent?: Maybe<Scalars['Boolean']>;
  answers?: Maybe<Array<Gds_CreateAnswerInput>>;
};

export type Gds_DeleteInvitationInput = {
  id: Scalars['String'];
  personId: Scalars['String'];
  householdId: Scalars['String'];
};

export type Gds_DeleteInvitationPayload = {
  __typename?: 'GDS_DeleteInvitationPayload';
  id?: Maybe<Scalars['String']>;
};

export type Wws_AccommodationItem = Wws_PageItem & {
  __typename?: 'WWS_AccommodationItem';
  address?: Maybe<Scalars['String']>;
  aspectRatio?: Maybe<Wws_AspectRatio>;
  checkInDate?: Maybe<Scalars['String']>;
  checkOutDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  guestCode?: Maybe<Scalars['String']>;
  guidance?: Maybe<Wws_PageItemGuidance>;
  hotelPlannerId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  originalPhoto?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photo?: Maybe<Wws_Photo>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  rate?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  website?: Maybe<Scalars['String']>;
};

/** A Page Item */
export type Wws_PageItem = {
  id: Scalars['Int'];
  rank: Scalars['Float'];
  type: Scalars['String'];
};

export type Wws_PageItemGuidance = {
  __typename?: 'WWS_PageItemGuidance';
  ctaText?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  image?: Maybe<Wws_MediaApiImage>;
  type?: Maybe<Scalars['String']>;
};

export type Wws_MediaApiImage = {
  __typename?: 'WWS_MediaApiImage';
  mediaApiId: Scalars['String'];
};

export type Wws_ActivityItem = Wws_PageItem & {
  __typename?: 'WWS_ActivityItem';
  address?: Maybe<Scalars['String']>;
  aspectRatio?: Maybe<Wws_AspectRatio>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  guidance?: Maybe<Wws_PageItemGuidance>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  originalPhoto?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photo?: Maybe<Wws_Photo>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  type: Scalars['String'];
  website?: Maybe<Scalars['String']>;
};

export type Wws_Address = {
  __typename?: 'WWS_Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type Wws_AlbumPhoto = {
  __typename?: 'WWS_AlbumPhoto';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  mediaApiId?: Maybe<Scalars['String']>;
  mediaUrl: Scalars['String'];
  photoAlbumId?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type Wws_Announcement = {
  __typename?: 'WWS_Announcement';
  icon?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
  show: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
};

export type Wws_BasicItem = Wws_PageItem & {
  __typename?: 'WWS_BasicItem';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  description?: Maybe<Scalars['String']>;
  guidance?: Maybe<Wws_PageItemGuidance>;
  id: Scalars['Int'];
  originalPhoto?: Maybe<Scalars['String']>;
  photo?: Maybe<Wws_Photo>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Wws_ContentItem = {
  __typename?: 'WWS_ContentItem';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  originalPhoto?: Maybe<Scalars['String']>;
  partnerFlg?: Maybe<Scalars['Boolean']>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type Wws_CoverPhoto = {
  __typename?: 'WWS_CoverPhoto';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  cropBox?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mobileCropBox?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  rotationAngle?: Maybe<Scalars['String']>;
};

export type Wws_CoverPhotoItem = Wws_PageItem & {
  __typename?: 'WWS_CoverPhotoItem';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  caption?: Maybe<Scalars['String']>;
  cropX?: Maybe<Scalars['Int']>;
  cropY?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  guidance?: Maybe<Wws_PageItemGuidance>;
  height: Scalars['Int'];
  id: Scalars['Int'];
  mediaApiId: Scalars['String'];
  mediaUrl: Scalars['String'];
  originalPhoto?: Maybe<Scalars['String']>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  rotation?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  width: Scalars['Int'];
};


export type Wws_EmbedItem = Wws_PageItem & {
  __typename?: 'WWS_EmbedItem';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  description?: Maybe<Scalars['String']>;
  embededCode: Scalars['String'];
  guidance?: Maybe<Wws_PageItemGuidance>;
  id: Scalars['Int'];
  mediaService: Scalars['String'];
  originalPhoto?: Maybe<Scalars['String']>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Wws_Error = Wws_WeddingWebsiteResult & {
  __typename?: 'WWS_Error';
  cause?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  theme?: Maybe<Wws_Theme>;
  weddingUuid?: Maybe<Scalars['String']>;
};

export type Wws_Event = {
  __typename?: 'WWS_Event';
  attire?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  eventDate?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
  /** @deprecated Unsupported by GDS */
  gifts?: Maybe<Array<Wws_Gift>>;
  /** @deprecated Unsupported by GDS */
  groups?: Maybe<Array<Wws_Group>>;
  /** @deprecated Unsupported by GDS */
  guestCount?: Maybe<Scalars['Int']>;
  hasTime?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  /** @deprecated Unsupported by GDS */
  invitations?: Maybe<Array<Wws_Invitation>>;
  location?: Maybe<Wws_Location>;
  meals?: Maybe<Array<Wws_Meal>>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  originalPhoto?: Maybe<Scalars['String']>;
  /** @deprecated Unsupported by GDS */
  people?: Maybe<Array<Wws_Person>>;
  /** @deprecated Unsupported by GDS */
  personIds?: Maybe<Array<Scalars['Int']>>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  rsvp?: Maybe<Scalars['Boolean']>;
  sameVenue?: Maybe<Scalars['Boolean']>;
  subEvents?: Maybe<Array<Wws_SubEvent>>;
  time?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type Wws_Gift = {
  __typename?: 'WWS_Gift';
  eventId?: Maybe<Scalars['Int']>;
  gift?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  invitationId?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
  sent?: Maybe<Scalars['Boolean']>;
};

export type Wws_Group = {
  __typename?: 'WWS_Group';
  event?: Maybe<Wws_Event>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  people?: Maybe<Array<Wws_Person>>;
  person?: Maybe<Wws_Person>;
  visualIndicator?: Maybe<Scalars['String']>;
};


export type Wws_GroupPersonArgs = {
  id: Scalars['Int'];
};

export type Wws_Person = {
  __typename?: 'WWS_Person';
  address?: Maybe<Wws_Address>;
  email?: Maybe<Scalars['String']>;
  /** @deprecated Unsupported by GDS */
  events?: Maybe<Array<Wws_Event>>;
  fianceFirstName?: Maybe<Scalars['String']>;
  fianceLastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  gifts?: Maybe<Array<Wws_Gift>>;
  id: Scalars['Int'];
  invitations?: Maybe<Array<Wws_Invitation>>;
  lastName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  partyLeader?: Maybe<Wws_Person>;
  partyMembers?: Maybe<Array<Wws_Person>>;
  phone?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type Wws_Invitation = {
  __typename?: 'WWS_Invitation';
  event?: Maybe<Wws_Event>;
  gift?: Maybe<Wws_Gift>;
  id: Scalars['Int'];
  meal?: Maybe<Wws_Meal>;
  person?: Maybe<Wws_Person>;
  rsvp?: Maybe<Scalars['Boolean']>;
  sent?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type Wws_Meal = {
  __typename?: 'WWS_Meal';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type Wws_Location = {
  __typename?: 'WWS_Location';
  addressComponents?: Maybe<Scalars['WWS_Json']>;
  fullAddress?: Maybe<Scalars['String']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  venueName?: Maybe<Scalars['String']>;
};

export type Wws_SubEvent = {
  __typename?: 'WWS_SubEvent';
  attire?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  location?: Maybe<Wws_Location>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type Wws_EventGroupItem = Wws_PageItem & {
  __typename?: 'WWS_EventGroupItem';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  guidance?: Maybe<Wws_PageItemGuidance>;
  id: Scalars['Int'];
  originalPhoto?: Maybe<Scalars['String']>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  type: Scalars['String'];
};

export type Wws_GifItem = Wws_PageItem & {
  __typename?: 'WWS_GifItem';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  guidance?: Maybe<Wws_PageItemGuidance>;
  id: Scalars['Int'];
  mediaApiId: Scalars['String'];
  mediaUrl: Scalars['String'];
  originalPhoto?: Maybe<Scalars['String']>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  type: Scalars['String'];
};

export type Wws_GroupItem = Wws_PageItem & {
  __typename?: 'WWS_GroupItem';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  childItems?: Maybe<Array<Wws_PageItem>>;
  description?: Maybe<Scalars['String']>;
  guidance?: Maybe<Wws_PageItemGuidance>;
  id: Scalars['Int'];
  originalPhoto?: Maybe<Scalars['String']>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Wws_HeadlineItem = Wws_PageItem & {
  __typename?: 'WWS_HeadlineItem';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  guidance?: Maybe<Wws_PageItemGuidance>;
  id: Scalars['Int'];
  originalPhoto?: Maybe<Scalars['String']>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  style?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Wws_Image = {
  __typename?: 'WWS_Image';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  caption?: Maybe<Scalars['String']>;
  columnNumber?: Maybe<Scalars['Int']>;
  galleryPath?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  mediaApiId?: Maybe<Scalars['String']>;
  mediaUrl: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  photoAlbumId?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  rankedOrder?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type Wws_ImageType = {
  __typename?: 'WWS_ImageType';
  aspectRatios: Array<Wws_AspectRatio>;
  defaultAspectRatio: Wws_AspectRatio;
  name: Scalars['String'];
};

export type Wws_LivestreamItem = Wws_PageItem & {
  __typename?: 'WWS_LivestreamItem';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  guidance?: Maybe<Wws_PageItemGuidance>;
  id: Scalars['Int'];
  originalPhoto?: Maybe<Scalars['String']>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Wws_LocationContent = {
  __typename?: 'WWS_LocationContent';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  checkInDate?: Maybe<Scalars['String']>;
  checkOutDate?: Maybe<Scalars['String']>;
  confirmationState?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expediaId?: Maybe<Scalars['String']>;
  guestCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Wws_Location>;
  memberWeddingId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  originalPhoto?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type Wws_Page = {
  __typename?: 'WWS_Page';
  /** Find a Accommodation Item by ID and Page ID */
  accommodationItem?: Maybe<Wws_AccommodationItem>;
  accommodationItems: Array<Wws_AccommodationItem>;
  /** Find a Activity Item by ID and Page ID */
  activityItem?: Maybe<Wws_ActivityItem>;
  /** Find a Basic Item by ID and Page ID */
  basicItem?: Maybe<Wws_BasicItem>;
  /** Find a Cover Photo Item by ID and Page ID */
  coverPhotoItem?: Maybe<Wws_CoverPhotoItem>;
  description?: Maybe<Scalars['String']>;
  /** Find a Embed Item by ID and Page ID */
  embedItem?: Maybe<Wws_EmbedItem>;
  /** Find a Gif Item by ID and Page ID */
  gifItem?: Maybe<Wws_GifItem>;
  /** Find a Headline Item by ID and Page ID */
  headlineItem?: Maybe<Wws_HeadlineItem>;
  id: Scalars['Int'];
  items: Array<Wws_PageItem>;
  /** Find a Livestream Item by and Page ID */
  livestreamItem?: Maybe<Wws_LivestreamItem>;
  pageItemGuidances?: Maybe<Array<Wws_PageItemGuidance>>;
  /** @deprecated Moved into pageItemGuidances */
  pageItemTypes: Array<Scalars['String']>;
  pageItems: Array<Wws_PageItem>;
  /** Find a Paragraph Item by ID and Page ID */
  paragraphItem?: Maybe<Wws_ParagraphItem>;
  /** Find a Person Item by ID and Page ID */
  personItem?: Maybe<Wws_PersonItem>;
  /** Find a Photo Gallery Item by ID and Page ID */
  photoGalleryItem?: Maybe<Wws_PhotoGalleryItem>;
  /** Find a Photo Item by ID and Page ID */
  photoItem?: Maybe<Wws_PhotoItem>;
  /** Find a Photo Timeline Item by ID and Page ID */
  photoTimelineItem?: Maybe<Wws_PhotoTimelineItem>;
  /** Find a Question Item by and Page ID */
  questionItem?: Maybe<Wws_QuestionItem>;
  rank?: Maybe<Scalars['Int']>;
  routeName?: Maybe<Scalars['String']>;
  sections?: Maybe<Array<Wws_Section>>;
  show?: Maybe<Scalars['Boolean']>;
  /** Find a Story Item by ID and Page ID */
  storyItem?: Maybe<Wws_StoryItem>;
  supportedImageTypes: Array<Wws_ImageType>;
  title?: Maybe<Scalars['String']>;
  /** Find a Transportation Item by ID and Page ID */
  transportationItem?: Maybe<Wws_TransportationItem>;
  type: Scalars['String'];
};


export type Wws_PageAccommodationItemArgs = {
  id: Scalars['Int'];
};


export type Wws_PageActivityItemArgs = {
  id: Scalars['Int'];
};


export type Wws_PageBasicItemArgs = {
  id: Scalars['Int'];
};


export type Wws_PageCoverPhotoItemArgs = {
  id: Scalars['Int'];
};


export type Wws_PageEmbedItemArgs = {
  id: Scalars['Int'];
};


export type Wws_PageGifItemArgs = {
  id: Scalars['Int'];
};


export type Wws_PageHeadlineItemArgs = {
  id: Scalars['Int'];
};


export type Wws_PageLivestreamItemArgs = {
  id: Scalars['Int'];
};


export type Wws_PageParagraphItemArgs = {
  id: Scalars['Int'];
};


export type Wws_PagePersonItemArgs = {
  id: Scalars['Int'];
};


export type Wws_PagePhotoGalleryItemArgs = {
  id: Scalars['Int'];
};


export type Wws_PagePhotoItemArgs = {
  id: Scalars['Int'];
};


export type Wws_PagePhotoTimelineItemArgs = {
  id: Scalars['Int'];
};


export type Wws_PageQuestionItemArgs = {
  id: Scalars['Int'];
};


export type Wws_PageStoryItemArgs = {
  id: Scalars['Int'];
};


export type Wws_PageTransportationItemArgs = {
  id: Scalars['Int'];
};

export type Wws_ParagraphItem = Wws_PageItem & {
  __typename?: 'WWS_ParagraphItem';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  content: Scalars['String'];
  guidance?: Maybe<Wws_PageItemGuidance>;
  id: Scalars['Int'];
  originalPhoto?: Maybe<Scalars['String']>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  textContent: Scalars['String'];
  type: Scalars['String'];
};

export type Wws_PersonItem = Wws_PageItem & {
  __typename?: 'WWS_PersonItem';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  description?: Maybe<Scalars['String']>;
  guidance?: Maybe<Wws_PageItemGuidance>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  originalPhoto?: Maybe<Scalars['String']>;
  photo?: Maybe<Wws_Photo>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  role?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Wws_PhotoGalleryItem = Wws_PageItem & {
  __typename?: 'WWS_PhotoGalleryItem';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  guidance?: Maybe<Wws_PageItemGuidance>;
  id: Scalars['Int'];
  layout: Scalars['String'];
  originalPhoto?: Maybe<Scalars['String']>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoItems: Array<Wws_PhotoItem>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  type: Scalars['String'];
};

export type Wws_PhotoItem = Wws_PageItem & {
  __typename?: 'WWS_PhotoItem';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  caption?: Maybe<Scalars['String']>;
  cropX?: Maybe<Scalars['Int']>;
  cropY?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  guidance?: Maybe<Wws_PageItemGuidance>;
  height: Scalars['Int'];
  id: Scalars['Int'];
  mediaApiId: Scalars['String'];
  mediaUrl: Scalars['String'];
  originalPhoto?: Maybe<Scalars['String']>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  rotation?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  width: Scalars['Int'];
};

export type Wws_PhotoTimelineItem = Wws_PageItem & {
  __typename?: 'WWS_PhotoTimelineItem';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  guidance?: Maybe<Wws_PageItemGuidance>;
  id: Scalars['Int'];
  layout: Scalars['String'];
  originalPhoto?: Maybe<Scalars['String']>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoItems: Array<Wws_PhotoItem>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  type: Scalars['String'];
};

export type Wws_QuestionItem = Wws_PageItem & {
  __typename?: 'WWS_QuestionItem';
  answer: Scalars['String'];
  aspectRatio?: Maybe<Wws_AspectRatio>;
  guidance?: Maybe<Wws_PageItemGuidance>;
  id: Scalars['Int'];
  originalPhoto?: Maybe<Scalars['String']>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  question: Scalars['String'];
  rank: Scalars['Float'];
  type: Scalars['String'];
};

export type Wws_Section = {
  __typename?: 'WWS_Section';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Wws_SectionContent>>;
  rank?: Maybe<Scalars['Int']>;
  show?: Maybe<Scalars['Boolean']>;
  showTitle?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** Items which can appear in sections */
export type Wws_SectionContent = Wws_SectionItem | Wws_Story | Wws_Event | Wws_ContentItem | Wws_LocationContent | Wws_PhotoAlbum;

export type Wws_SectionItem = {
  __typename?: 'WWS_SectionItem';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  originalPhoto?: Maybe<Scalars['String']>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Wws_Story = {
  __typename?: 'WWS_Story';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  originalPhoto?: Maybe<Scalars['String']>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Wws_PhotoAlbum = {
  __typename?: 'WWS_PhotoAlbum';
  coverPhoto?: Maybe<Wws_Image>;
  fbAlbumId?: Maybe<Scalars['String']>;
  fbSync?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  images?: Maybe<Array<Wws_Image>>;
  importing?: Maybe<Scalars['Boolean']>;
  instagramAlbum?: Maybe<Scalars['Boolean']>;
  memberWeddingId?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  photos: Array<Wws_AlbumPhoto>;
  rank?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type Wws_StoryItem = Wws_PageItem & {
  __typename?: 'WWS_StoryItem';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  description?: Maybe<Scalars['String']>;
  guidance?: Maybe<Wws_PageItemGuidance>;
  id: Scalars['Int'];
  originalPhoto?: Maybe<Scalars['String']>;
  photo?: Maybe<Wws_Photo>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Wws_TransportationItem = Wws_PageItem & {
  __typename?: 'WWS_TransportationItem';
  address?: Maybe<Scalars['String']>;
  aspectRatio?: Maybe<Wws_AspectRatio>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  guidance?: Maybe<Wws_PageItemGuidance>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  originalPhoto?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photo?: Maybe<Wws_Photo>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  type: Scalars['String'];
  website?: Maybe<Scalars['String']>;
};

export type Wws_PurchaseDomain = {
  __typename?: 'WWS_PurchaseDomain';
  createdAt: Scalars['String'];
  dateOfLastRenewal?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isInRedemptionPeriod: Scalars['Boolean'];
  isRenewable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  renewalPrice: Scalars['Float'];
};

export type Wws_WeddingPartyItem = Wws_PageItem & {
  __typename?: 'WWS_WeddingPartyItem';
  aspectRatio?: Maybe<Wws_AspectRatio>;
  childItems?: Maybe<Array<Wws_PageItem>>;
  description?: Maybe<Scalars['String']>;
  guidance?: Maybe<Wws_PageItemGuidance>;
  id: Scalars['Int'];
  originalPhoto?: Maybe<Scalars['String']>;
  people?: Maybe<Array<Wws_PageItem>>;
  photoHeight?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoWidth?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Wws_WeddingWebsiteV2 = Wws_WeddingWebsiteResult & {
  __typename?: 'WWS_WeddingWebsiteV2';
  announcement?: Maybe<Wws_Announcement>;
  contentItems?: Maybe<Array<Wws_ContentItem>>;
  coverPhoto?: Maybe<Wws_CoverPhoto>;
  fianceFirstName?: Maybe<Scalars['String']>;
  fianceLastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hideCountdown?: Maybe<Scalars['Boolean']>;
  hideDate?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  locationContents?: Maybe<Array<Wws_LocationContent>>;
  pages?: Maybe<Array<Wws_Page>>;
  photoAlbums?: Maybe<Array<Wws_PhotoAlbum>>;
  pinProtect?: Maybe<Scalars['Boolean']>;
  purchaseDomain?: Maybe<Wws_PurchaseDomain>;
  registryNote?: Maybe<Scalars['String']>;
  searchEngineIndexable?: Maybe<Scalars['Boolean']>;
  searchable?: Maybe<Scalars['Boolean']>;
  slug: Scalars['String'];
  stories?: Maybe<Array<Wws_Story>>;
  theme?: Maybe<Wws_Theme>;
  userUuid?: Maybe<Scalars['String']>;
  vanityRoot?: Maybe<Scalars['String']>;
  weddingDateFormat?: Maybe<Scalars['String']>;
  weddingDateRangeEnd?: Maybe<Scalars['WWS_Date']>;
  weddingDateRangeStart?: Maybe<Scalars['WWS_Date']>;
  weddingLocation?: Maybe<Scalars['String']>;
  weddingUuid?: Maybe<Scalars['String']>;
};


export type Wws_WeddingWebsiteV2ThemeArgs = {
  themeId?: Maybe<Scalars['String']>;
};



export type PhotosFragment = { __typename?: 'WWS_Photo', mediaUrl: string, mediaApiId: string, width?: Maybe<number>, height?: Maybe<number>, cropX?: Maybe<number>, cropY?: Maybe<number>, rotation?: Maybe<number>, aspectRatio?: Maybe<{ __typename?: 'WWS_AspectRatio', name: string, value: number }> };

export type LocationPropsFragment = { __typename?: 'GDS_Location', fullAddress?: Maybe<string>, name?: Maybe<string> };

export type AnnouncementFragment = { __typename?: 'WWS_Announcement', id?: Maybe<number>, icon?: Maybe<number>, message: string, show: boolean };

export type ThemeFragment = { __typename?: 'WWS_Theme', id?: Maybe<number>, name?: Maybe<string>, themeStyles?: Maybe<any>, themeDesigner?: Maybe<{ __typename?: 'WWS_ThemeDesigner', name?: Maybe<string>, companyName?: Maybe<string> }> };

export type HeadlineItemFragment = { __typename?: 'WWS_HeadlineItem', id: number, type: string, rank: number, style?: Maybe<string>, title?: Maybe<string> };

export type ParagraphItemFragment = { __typename?: 'WWS_ParagraphItem', id: number, content: string, rank: number, type: string };

export type AccommodationItemFragment = { __typename?: 'WWS_AccommodationItem', id: number, rank: number, type: string, name?: Maybe<string>, description?: Maybe<string>, address?: Maybe<string>, phone?: Maybe<string>, email?: Maybe<string>, website?: Maybe<string>, guestCode?: Maybe<string>, checkInDate?: Maybe<string>, checkOutDate?: Maybe<string>, rate?: Maybe<string>, photo?: Maybe<(
    { __typename?: 'WWS_Photo' }
    & PhotosFragment
  )> };

export type ActivityItemFragment = { __typename?: 'WWS_ActivityItem', id: number, rank: number, type: string, name?: Maybe<string>, description?: Maybe<string>, address?: Maybe<string>, phone?: Maybe<string>, email?: Maybe<string>, website?: Maybe<string>, photo?: Maybe<(
    { __typename?: 'WWS_Photo' }
    & PhotosFragment
  )> };

export type BasicItemFragment = { __typename?: 'WWS_BasicItem', id: number, rank: number, type: string, title?: Maybe<string>, description?: Maybe<string>, photo?: Maybe<(
    { __typename?: 'WWS_Photo' }
    & PhotosFragment
  )> };

export type StoryItemFragment = { __typename?: 'WWS_StoryItem', id: number, rank: number, type: string, title?: Maybe<string>, description?: Maybe<string>, photo?: Maybe<(
    { __typename?: 'WWS_Photo' }
    & PhotosFragment
  )> };

export type TransportationItemFragment = { __typename?: 'WWS_TransportationItem', id: number, rank: number, type: string, name?: Maybe<string>, description?: Maybe<string>, address?: Maybe<string>, phone?: Maybe<string>, email?: Maybe<string>, website?: Maybe<string>, photo?: Maybe<(
    { __typename?: 'WWS_Photo' }
    & PhotosFragment
  )> };

export type PersonItemFragment = { __typename?: 'WWS_PersonItem', id: number, rank: number, type: string, name?: Maybe<string>, description?: Maybe<string>, role?: Maybe<string>, photo?: Maybe<(
    { __typename?: 'WWS_Photo' }
    & PhotosFragment
  )> };

export type PhotoItemFragment = { __typename?: 'WWS_PhotoItem', id: number, mediaUrl: string, rotation?: Maybe<number>, type: string, aspectRatio?: Maybe<{ __typename?: 'WWS_AspectRatio', name: string }> };

export type GifItemFragment = { __typename?: 'WWS_GifItem', id: number, rank: number, type: string, mediaUrl: string };

export type QuestionItemFragment = { __typename?: 'WWS_QuestionItem', id: number, rank: number, type: string, question: string, answer: string };

export type LivestreamItemFragment = { __typename?: 'WWS_LivestreamItem', id: number, rank: number, type: string, title?: Maybe<string>, description?: Maybe<string>, buttonText?: Maybe<string>, buttonUrl?: Maybe<string> };

export type EmbedItemFragment = { __typename?: 'WWS_EmbedItem', id: number, rank: number, type: string, title?: Maybe<string>, description?: Maybe<string>, embededCode: string };

export type PhotoGalleryItemFragment = { __typename?: 'WWS_PhotoGalleryItem', id: number, rank: number, type: string, layout: string, photoItems: Array<{ __typename?: 'WWS_PhotoItem', caption?: Maybe<string>, height: number, id: number, mediaApiId: string, cropX?: Maybe<number>, cropY?: Maybe<number>, rotation?: Maybe<number>, mediaUrl: string, width: number }> };

export type PhotoTimelineItemFragment = { __typename?: 'WWS_PhotoTimelineItem', id: number, rank: number, type: string, layout: string, photoItems: Array<{ __typename?: 'WWS_PhotoItem', caption?: Maybe<string>, date?: Maybe<string>, height: number, id: number, mediaApiId: string, mediaUrl: string, width: number }> };

export type EventGroupItemFragment = { __typename?: 'WWS_EventGroupItem', id: number, type: string, rank: number };

export type WwsPagesFragment = { __typename?: 'WWS_WeddingWebsiteV2', pages?: Maybe<Array<{ __typename?: 'WWS_Page', id: number, routeName?: Maybe<string>, show?: Maybe<boolean>, title?: Maybe<string>, type: string, items: Array<(
      { __typename?: 'WWS_AccommodationItem', id: number, type: string }
      & AccommodationItemFragment
    ) | (
      { __typename?: 'WWS_ActivityItem', id: number, type: string }
      & ActivityItemFragment
    ) | (
      { __typename?: 'WWS_BasicItem', id: number, type: string }
      & BasicItemFragment
    ) | { __typename?: 'WWS_CoverPhotoItem', id: number, type: string } | (
      { __typename?: 'WWS_EmbedItem', id: number, type: string }
      & EmbedItemFragment
    ) | (
      { __typename?: 'WWS_EventGroupItem', id: number, type: string }
      & EventGroupItemFragment
    ) | (
      { __typename?: 'WWS_GifItem', id: number, type: string }
      & GifItemFragment
    ) | { __typename?: 'WWS_GroupItem', id: number, type: string } | (
      { __typename?: 'WWS_HeadlineItem', id: number, type: string }
      & HeadlineItemFragment
    ) | (
      { __typename?: 'WWS_LivestreamItem', id: number, type: string }
      & LivestreamItemFragment
    ) | (
      { __typename?: 'WWS_ParagraphItem', id: number, type: string }
      & ParagraphItemFragment
    ) | (
      { __typename?: 'WWS_PersonItem', id: number, type: string }
      & PersonItemFragment
    ) | (
      { __typename?: 'WWS_PhotoGalleryItem', id: number, type: string }
      & PhotoGalleryItemFragment
    ) | (
      { __typename?: 'WWS_PhotoItem', id: number, type: string }
      & PhotoItemFragment
    ) | (
      { __typename?: 'WWS_PhotoTimelineItem', id: number, type: string }
      & PhotoTimelineItemFragment
    ) | (
      { __typename?: 'WWS_QuestionItem', id: number, type: string }
      & QuestionItemFragment
    ) | (
      { __typename?: 'WWS_StoryItem', id: number, type: string }
      & StoryItemFragment
    ) | (
      { __typename?: 'WWS_TransportationItem', id: number, type: string }
      & TransportationItemFragment
    ) | { __typename?: 'WWS_WeddingPartyItem', id: number, type: string }> }>> };

export type PhotoAlbumsFragment = { __typename?: 'WWS_WeddingWebsiteV2', photoAlbums?: Maybe<Array<{ __typename?: 'WWS_PhotoAlbum', id?: Maybe<number>, title?: Maybe<string>, images?: Maybe<Array<{ __typename?: 'WWS_Image', galleryPath?: Maybe<string>, id?: Maybe<number>, path?: Maybe<string>, caption?: Maybe<string> }>> }>> };

export type WwsResultFragment = (
  { __typename?: 'WWS_WeddingWebsiteV2', id: number, fianceFirstName?: Maybe<string>, fianceLastName?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, hideCountdown?: Maybe<boolean>, hideDate?: Maybe<boolean>, registryNote?: Maybe<string>, slug: string, userUuid?: Maybe<string>, vanityRoot?: Maybe<string>, weddingLocation?: Maybe<string>, weddingDateFormat?: Maybe<string>, searchEngineIndexable?: Maybe<boolean>, weddingDateRangeStart?: Maybe<any>, weddingDateRangeEnd?: Maybe<any>, weddingUuid?: Maybe<string>, theme?: Maybe<(
    { __typename?: 'WWS_Theme' }
    & ThemeFragment
  )>, announcement?: Maybe<(
    { __typename?: 'WWS_Announcement' }
    & AnnouncementFragment
  )>, purchaseDomain?: Maybe<{ __typename?: 'WWS_PurchaseDomain', id?: Maybe<number>, name?: Maybe<string> }>, coverPhoto?: Maybe<{ __typename?: 'WWS_CoverPhoto', path?: Maybe<string>, cropBox?: Maybe<string> }> }
  & PhotoAlbumsFragment
  & WwsPagesFragment
);

export type WwsErrorFragment = { __typename?: 'WWS_Error', cause?: Maybe<string>, theme?: Maybe<{ __typename?: 'WWS_Theme', id?: Maybe<number>, themeStyles?: Maybe<any> }> };

export type SubEventFragment = { __typename?: 'GDS_SubEvent', attire?: Maybe<string>, endTime?: Maybe<string>, id: string, name?: Maybe<string>, notes?: Maybe<string>, time?: Maybe<string>, type?: Maybe<string>, visible?: Maybe<boolean>, location?: Maybe<(
    { __typename?: 'GDS_Location' }
    & LocationPropsFragment
  )> };

export type EventsFragment = { __typename?: 'GDS_Event', id: string, attire?: Maybe<string>, endTime?: Maybe<string>, name?: Maybe<string>, notes?: Maybe<string>, time?: Maybe<string>, type?: Maybe<string>, visible?: Maybe<boolean>, date?: Maybe<string>, requireRsvp?: Maybe<boolean>, sameVenue?: Maybe<boolean>, location?: Maybe<(
    { __typename?: 'GDS_Location' }
    & LocationPropsFragment
  )>, subEvents?: Maybe<Array<(
    { __typename?: 'GDS_SubEvent' }
    & SubEventFragment
  )>> };

export type WeddingWebsiteQueryVariables = Exact<{
  slug: Scalars['String'];
  pin?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['String']>;
}>;


export type WeddingWebsiteQuery = { __typename?: 'Query', guestWedding?: Maybe<{ __typename?: 'GDS_GuestWedding', id: string, isPrivateRsvp?: Maybe<boolean>, rsvpAsAPage?: Maybe<boolean>, rsvpPageHidden?: Maybe<boolean> }>, events: Array<(
    { __typename?: 'GDS_Event' }
    & EventsFragment
  )>, weddingWebsiteResult?: Maybe<(
    { __typename?: 'WWS_Error' }
    & WwsErrorFragment
  ) | (
    { __typename?: 'WWS_WeddingWebsiteV2' }
    & WwsResultFragment
  )>, wedding?: Maybe<{ __typename?: 'Wedding', weddingDate: string, season?: Maybe<{ __typename?: 'Season', name?: Maybe<SeasonName>, year?: Maybe<number> }> }> };

export const ThemeFragmentDoc = gql`
    fragment Theme on WWS_Theme {
  id
  name
  themeStyles
  themeDesigner {
    name
    companyName
  }
}
    `;
export const AnnouncementFragmentDoc = gql`
    fragment Announcement on WWS_Announcement {
  id
  icon
  message
  show
}
    `;
export const PhotoAlbumsFragmentDoc = gql`
    fragment PhotoAlbums on WWS_WeddingWebsiteV2 {
  photoAlbums {
    id
    title
    images {
      galleryPath
      id
      path
      caption
    }
  }
}
    `;
export const PhotosFragmentDoc = gql`
    fragment Photos on WWS_Photo {
  mediaUrl
  mediaApiId
  width
  height
  cropX
  cropY
  rotation
  aspectRatio {
    name
    value
  }
}
    `;
export const AccommodationItemFragmentDoc = gql`
    fragment AccommodationItem on WWS_AccommodationItem {
  id
  rank
  type
  name
  description
  address
  phone
  photo {
    ...Photos
  }
  email
  website
  guestCode
  checkInDate
  checkOutDate
  rate
}
    ${PhotosFragmentDoc}`;
export const ActivityItemFragmentDoc = gql`
    fragment ActivityItem on WWS_ActivityItem {
  id
  rank
  type
  name
  description
  photo {
    ...Photos
  }
  address
  phone
  email
  website
}
    ${PhotosFragmentDoc}`;
export const BasicItemFragmentDoc = gql`
    fragment BasicItem on WWS_BasicItem {
  id
  rank
  type
  title
  description
  photo {
    ...Photos
  }
}
    ${PhotosFragmentDoc}`;
export const EventGroupItemFragmentDoc = gql`
    fragment EventGroupItem on WWS_EventGroupItem {
  id
  type
  rank
}
    `;
export const HeadlineItemFragmentDoc = gql`
    fragment HeadlineItem on WWS_HeadlineItem {
  id
  type
  rank
  style
  title
}
    `;
export const ParagraphItemFragmentDoc = gql`
    fragment ParagraphItem on WWS_ParagraphItem {
  id
  content
  rank
  type
}
    `;
export const PersonItemFragmentDoc = gql`
    fragment PersonItem on WWS_PersonItem {
  id
  rank
  type
  name
  description
  role
  photo {
    ...Photos
  }
}
    ${PhotosFragmentDoc}`;
export const PhotoItemFragmentDoc = gql`
    fragment PhotoItem on WWS_PhotoItem {
  aspectRatio {
    name
  }
  id
  mediaUrl
  rotation
  type
}
    `;
export const PhotoGalleryItemFragmentDoc = gql`
    fragment PhotoGalleryItem on WWS_PhotoGalleryItem {
  id
  rank
  type
  layout
  photoItems {
    caption
    height
    id
    mediaApiId
    cropX
    cropY
    rotation
    mediaUrl
    width
  }
}
    `;
export const PhotoTimelineItemFragmentDoc = gql`
    fragment PhotoTimelineItem on WWS_PhotoTimelineItem {
  id
  rank
  type
  layout
  photoItems {
    caption
    date
    height
    id
    mediaApiId
    mediaUrl
    width
  }
}
    `;
export const StoryItemFragmentDoc = gql`
    fragment StoryItem on WWS_StoryItem {
  id
  rank
  type
  title
  description
  photo {
    ...Photos
  }
}
    ${PhotosFragmentDoc}`;
export const TransportationItemFragmentDoc = gql`
    fragment TransportationItem on WWS_TransportationItem {
  id
  rank
  type
  name
  description
  photo {
    ...Photos
  }
  address
  phone
  email
  website
}
    ${PhotosFragmentDoc}`;
export const GifItemFragmentDoc = gql`
    fragment GifItem on WWS_GifItem {
  id
  rank
  type
  mediaUrl
}
    `;
export const EmbedItemFragmentDoc = gql`
    fragment EmbedItem on WWS_EmbedItem {
  id
  rank
  type
  title
  description
  embededCode
}
    `;
export const QuestionItemFragmentDoc = gql`
    fragment QuestionItem on WWS_QuestionItem {
  id
  rank
  type
  question
  answer
}
    `;
export const LivestreamItemFragmentDoc = gql`
    fragment LivestreamItem on WWS_LivestreamItem {
  id
  rank
  type
  title
  description
  buttonText
  buttonUrl
}
    `;
export const WwsPagesFragmentDoc = gql`
    fragment WWSPages on WWS_WeddingWebsiteV2 {
  pages {
    id
    routeName
    show
    title
    type
    items {
      id
      type
      ...AccommodationItem
      ...ActivityItem
      ...BasicItem
      ...EventGroupItem
      ...HeadlineItem
      ...ParagraphItem
      ...PersonItem
      ...PhotoItem
      ...PhotoGalleryItem
      ...PhotoTimelineItem
      ...StoryItem
      ...TransportationItem
      ...GifItem
      ...EmbedItem
      ...QuestionItem
      ...LivestreamItem
    }
  }
}
    ${AccommodationItemFragmentDoc}
${ActivityItemFragmentDoc}
${BasicItemFragmentDoc}
${EventGroupItemFragmentDoc}
${HeadlineItemFragmentDoc}
${ParagraphItemFragmentDoc}
${PersonItemFragmentDoc}
${PhotoItemFragmentDoc}
${PhotoGalleryItemFragmentDoc}
${PhotoTimelineItemFragmentDoc}
${StoryItemFragmentDoc}
${TransportationItemFragmentDoc}
${GifItemFragmentDoc}
${EmbedItemFragmentDoc}
${QuestionItemFragmentDoc}
${LivestreamItemFragmentDoc}`;
export const WwsResultFragmentDoc = gql`
    fragment WWSResult on WWS_WeddingWebsiteV2 {
  id
  theme(themeId: $themeId) {
    ...Theme
  }
  fianceFirstName
  fianceLastName
  firstName
  lastName
  hideCountdown
  hideDate
  registryNote
  slug
  userUuid
  vanityRoot
  weddingLocation
  weddingDateFormat
  searchEngineIndexable
  weddingDateRangeStart
  weddingDateRangeEnd
  weddingUuid
  announcement {
    ...Announcement
  }
  purchaseDomain {
    id
    name
  }
  coverPhoto {
    path
    cropBox
  }
  ...PhotoAlbums
  ...WWSPages
}
    ${ThemeFragmentDoc}
${AnnouncementFragmentDoc}
${PhotoAlbumsFragmentDoc}
${WwsPagesFragmentDoc}`;
export const WwsErrorFragmentDoc = gql`
    fragment WWSError on WWS_Error {
  cause
  theme {
    id
    themeStyles
  }
}
    `;
export const LocationPropsFragmentDoc = gql`
    fragment LocationProps on GDS_Location {
  fullAddress
  name
}
    `;
export const SubEventFragmentDoc = gql`
    fragment SubEvent on GDS_SubEvent {
  attire
  endTime
  id
  name
  notes
  time
  type
  visible
  location {
    ...LocationProps
  }
}
    ${LocationPropsFragmentDoc}`;
export const EventsFragmentDoc = gql`
    fragment Events on GDS_Event {
  id
  attire
  endTime
  name
  notes
  time
  type
  visible
  date
  name
  time
  requireRsvp
  sameVenue
  location {
    ...LocationProps
  }
  subEvents {
    ...SubEvent
  }
}
    ${LocationPropsFragmentDoc}
${SubEventFragmentDoc}`;
export const WeddingWebsiteQueryDocument = gql`
    query weddingWebsiteQuery($slug: String!, $pin: String, $themeId: String) {
  guestWedding {
    id
    isPrivateRsvp
    rsvpAsAPage
    rsvpPageHidden
  }
  events {
    ...Events
  }
  weddingWebsiteResult(slugOrVanity: $slug, pin: $pin) {
    ...WWSError
    ...WWSResult
  }
  wedding {
    weddingDate
    season {
      name
      year
    }
  }
}
    ${EventsFragmentDoc}
${WwsErrorFragmentDoc}
${WwsResultFragmentDoc}`;

/**
 * __useWeddingWebsiteQuery__
 *
 * To run a query within a React component, call `useWeddingWebsiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeddingWebsiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeddingWebsiteQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      pin: // value for 'pin'
 *      themeId: // value for 'themeId'
 *   },
 * });
 */
export function useWeddingWebsiteQuery(baseOptions?: Apollo.QueryHookOptions<WeddingWebsiteQuery, WeddingWebsiteQueryVariables>) {
        return Apollo.useQuery<WeddingWebsiteQuery, WeddingWebsiteQueryVariables>(WeddingWebsiteQueryDocument, baseOptions);
      }
export function useWeddingWebsiteQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WeddingWebsiteQuery, WeddingWebsiteQueryVariables>) {
          return Apollo.useLazyQuery<WeddingWebsiteQuery, WeddingWebsiteQueryVariables>(WeddingWebsiteQueryDocument, baseOptions);
        }
export type WeddingWebsiteQueryHookResult = ReturnType<typeof useWeddingWebsiteQuery>;
export type WeddingWebsiteQueryLazyQueryHookResult = ReturnType<typeof useWeddingWebsiteQueryLazyQuery>;
export type WeddingWebsiteQueryQueryResult = Apollo.QueryResult<WeddingWebsiteQuery, WeddingWebsiteQueryVariables>;