import React from 'react';

import styled from '../../lib/styled';
import { themeFont } from '../../lib/themeFont';

const FooterText = styled.div`
  line-height: 1.75;
  ${themeFont('tiny')}
  margin: 12px 0;
  text-align: center;
`;
const Link = styled.a`
  ${themeFont('tiny')};
  ${(props) => props.theme.components.Link};
`;
interface Props {
  designer: any;
}

const ThemeAttribution: React.FC<Props> = ({ designer }) => {
  let designerByLine = '.';
  if (designer?.name) {
    designerByLine = `, designed by ${designer.name}.`;
  }
  return (
    <FooterText>
      Created on The Knot
      {designerByLine}
      <br />
      {'Getting married? '}
      <Link
        href="https://www.theknot.com/gs/wedding-websites"
        rel="noopener noreferrer"
        style={{ textDecoration: 'underline' }}
        target="_blank"
      >
        Create your wedding website for free.
      </Link>
    </FooterText>
  );
};
export default ThemeAttribution;
