import { useContext, useEffect, useState } from 'react';

import { TrackingContext } from '../components/TrackingContext';
import { WWContext } from '../components/WWContext';
import { getPageNameFromRouteName } from './utils';

const EventNames = {
  photo: 'Photo Inspecting',
  rsvp: 'RSVP Interaction',
  guest: 'Guest Interaction',
  visit: 'Wedding Website Guest Visit',
};

type Keys = keyof typeof EventNames;

const useTracking = (eventType: string, routeName: string, contextArea?: string) => {
  const { trackingInfo, trackingInfoLoaded } = useContext(TrackingContext);
  const { data } = useContext(WWContext);
  const { hideDate, announcement } = data.weddingWebsiteResult;
  const { message, show: showAnnouncement } = announcement || {};
  const currentPage = getPageNameFromRouteName(routeName);
  const [trackCalls, setTrackCalls] = useState<{ selection: string; userDecisionArea?: string }[]>([]);

  const track = (selection: string, trackArea?: string) => {
    const userDecisionArea = contextArea || trackArea;

    setTrackCalls([
      ...trackCalls,
      {
        selection,
        userDecisionArea,
      },
    ]);
  };

  useEffect(() => {
    if (trackingInfoLoaded && trackCalls.length > 0 && (window as any).analytics?.track) {
      trackCalls.map(({ selection, userDecisionArea }) => {
        (window as any).analytics.track(EventNames[eventType as Keys], {
          selection,
          userDecisionArea,
          ...trackingInfo,
          currentPage,
          dateHidden: hideDate || false,
          hasAnnouncement: (announcement && showAnnouncement) || false,
          announcementMessage: message || null,
        });
        return true;
      });
      setTrackCalls([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingInfoLoaded, trackCalls]);

  return track;
};

export default useTracking;
