import css from '@emotion/css';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import ReactModal from 'react-modal';

import mediaApiUrl, { extractParamsFromMediaUrl } from '../lib/mediaApiUrl';
import styled from '../lib/styled';
import { themeFont } from '../lib/themeFont';
import { useCurrentRoute } from '../lib/useCurrentRoute';
import useTracking from '../lib/useTracking';
import Picture from './Picture';
import ArrowLeft from './svgs/ArrowLeft';
import ArrowRight from './svgs/ArrowRight';

const ImgCustom = styled.img`
  background-color: rgb(216, 216, 216);
  border: 1px solid rgb(151, 151, 151);
  cursor: pointer;
  height: 295px;
  margin: 10px;
  width: 295px;
  object-fit: contain;
  ${(props) => props.theme.components.Photo};
`;
const LightboxPhoto = styled.img`
  margin: auto;
  max-height: 100vh;
  max-width: 100vw;
  :hover {
    + a {
      fill: red;
    }
  }
`;
const arrowStyles = css`
  cursor: pointer;
  fill: white;
  filter: drop-shadow(0 0 5px black);
  height: 75px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 75px;
  background-color: rgba(0, 0, 0, 0.1);
`;
const PrevArrow = styled(ArrowLeft)`
  ${arrowStyles}
  left: 0;
`;
const NextArrow = styled(ArrowRight)`
  ${arrowStyles}
  right: 0;
`;
const StyledModal = styled(ReactModal)`
  background: ${(props) => props.theme.colors.background};
  border-radius: 0;
  border: none;
  bottom: unset;
  display: flex;
  flex-direction: column;
  left: 50%;
  max-height: 100vh;
  outline: none;
  padding: 0;
  position: fixed;
  right: unset;
  top: 50%;
  transform: translate(-50%, -50%);
  width: min-content;

  @media (min-width: 768px) {
    svg {
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
    }

    :hover {
      svg {
        opacity: 1;
      }
    }
  }
`;
const Caption = styled.div`
  ${themeFont('s6')}
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  color: white;
  padding: 8px 16px;
  position: absolute;
  width: 100%;
`;
interface PhotoProps {
  alt: string;
  caption?: string;
  galleryPath?: string;
  id: number;
  nextPhotoId?: number;
  path: string;
  prevPhotoId?: number;
  crop?: string;
  rotation?: number;
  dimensions?: {
    width: number;
    height: number;
  };
}
const Photo: React.FC<PhotoProps> = ({
  alt,
  caption,
  nextPhotoId,
  path,
  prevPhotoId,
  id,
  crop,
  rotation = 0,
  dimensions,
}) => {
  const router = useRouter();
  const currentPage = useCurrentRoute();
  useEffect(() => {
    const appRoot = document.getElementById('__next');
    if (appRoot) {
      ReactModal.setAppElement('#__next');
    } else {
      ReactModal.setAppElement('body');
    }
  }, []);

  const track = useTracking('photo', 'photos');
  const fullSizePath = path.split('~')[0];
  const { query } = router;

  let isModalOpen = false;

  const photoId = query.route[1];

  if (photoId) {
    isModalOpen = parseInt(photoId, 10) === id;
  }

  const closeModel = () => {
    const { id, ...rest } = query;
    router.push(
      {
        path: '/[pathPrefix]/[slug]/[route]',
        query: {
          ...rest,
          route: currentPage,
        },
      },
      undefined,
      {
        shallow: true,
      }
    );
  };

  const size = 600;
  const { url, format } = extractParamsFromMediaUrl(fullSizePath);

  return (
    <>
      <Link
        href={{
          pathname: '/[pathPrefix]/[slug]/[route]/[id]',
          query: {
            ...query,
            id,
          },
        }}
        shallow
        passHref={false}
      >
        <Picture
          ImgCustom={ImgCustom}
          url={fullSizePath}
          smartCrop={{ width: size, height: size }}
          alt={alt}
          sm={size}
          md={size}
          lg={size}
          xl={size}
          rotation={rotation}
          onClick={() => {
            track('open photo modal', 'click photo');
          }}
        />
      </Link>

      <StyledModal
        style={{
          overlay: {
            zIndex: 1100,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
        }}
        isOpen={isModalOpen}
        onRequestClose={() => {
          track('close photo modal', 'click outside modal');
          closeModel();
        }}
      >
        {prevPhotoId && (
          <Link
            href={{
              pathname: '/[pathPrefix]/[slug]/[route]/[id]',
              query: {
                ...query,
                route: currentPage,
                id: prevPhotoId,
              },
            }}
            shallow
          >
            <a>
              <PrevArrow title="Previous Photo" />
            </a>
          </Link>
        )}

        <LightboxPhoto
          alt={`${alt} - Full Size`}
          src={crop && dimensions ? mediaApiUrl({ crop, format, rotation, url, ...dimensions }) : fullSizePath}
        />
        {caption && <Caption>{caption}</Caption>}

        {nextPhotoId && (
          <Link
            href={{
              pathname: '/[pathPrefix]/[slug]/[route]/[id]',
              query: {
                ...query,
                route: currentPage,
                id: nextPhotoId,
              },
            }}
            shallow
          >
            <a>
              <NextArrow title="Next Photo" />
            </a>
          </Link>
        )}
      </StyledModal>
    </>
  );
};
export default Photo;
