// TODO: Need a Better file name
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useMemo } from 'react';

import sendPostMessage from '../lib/sendPostMessage';
import { useIsLiteSite } from '../lib/useIsLiteSite';
import { useRsvpIsVisible } from '../lib/useRsvpIsVisible';
import { isClient } from '../lib/utils';
import { RegistryProvider } from './RegistryContext';
import Seo from './Seo';
import BaseTemplate from './Templates/BaseTemplate';
import LiteSiteTemplate from './Templates/LiteSiteTemplate';
import RsvpTemplate from './Templates/RsvpTemplate';
import { TrackingProvider } from './TrackingContext';
import { WWContext } from './WWContext';

const Main: React.FC = () => {
  const { query, replace, reload } = useRouter();
  const isRsvpVisible = useRsvpIsVisible();
  const { data } = useContext(WWContext);
  const { pages } = data.weddingWebsiteResult;
  const { setWWData } = useContext(WWContext);
  const routeName = Array.isArray(query.route) ? query.route[0] : !query.route ? '' : query.route;
  const currentPage = useMemo(() => pages?.find((p) => p.routeName === routeName), [pages, routeName]);
  const isLiteSite = useIsLiteSite();

  useEffect(() => {
    if (!currentPage && routeName === 'rsvp') {
      sendPostMessage({ type: 'currentPage', data: 'rsvp' });
    } else {
      sendPostMessage({ type: 'currentPage', data: currentPage });
    }
  }, [currentPage]);

  useEffect(() => {
    const handler = (event: MessageEvent) => {
      if (event.source !== window) {
        try {
          const message = JSON.parse(event.data);
          switch (message.type) {
            case 'currentPage':
              return replace(`/${query.pathPrefix}/${query.slug}/${message.data.routeName}`);
            case 'updatePage':
              return setWWData({
                ...data,
                weddingWebsiteResult: {
                  ...data.weddingWebsiteResult,
                  pages: (pages || []).map((page) => (page.id === message.data.id ? message.data : page)),
                },
              });
            case 'updateRegistryNote':
              // If anything else on weddingWebsiteQuery needs to be update create a case for that
              return setWWData({
                ...data,
                weddingWebsiteResult: {
                  ...data.weddingWebsiteResult,
                  registryNote: message.data,
                },
              });
            case 'reload':
              reload();
              return;
          }
        } catch (error) {
          // Ignore the error?
        }
      }
    };

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, []);

  let Template;

  const preview = (query?.preview as string) === 'true';

  if (query.route && query.route[0] === 'rsvp') {
    if (!isRsvpVisible && isClient() && !preview) {
      replace('/404');
      return null;
    }

    Template = RsvpTemplate;
  } else if (isLiteSite) {
    Template = LiteSiteTemplate;
  } else {
    Template = BaseTemplate;
  }

  return (
    <>
      <Seo data={data} />
      <RegistryProvider data={data}>
        <TrackingProvider data={data}>
          <Template themeOverride={(query.themeOverride as string) || ''} />
        </TrackingProvider>
      </RegistryProvider>
    </>
  );
};

export default Main;
