import React from 'react';

import styled from '../lib/styled';
import { useNavLink } from '../lib/useNavLinks';
import MobileNavList from './Layout/Nav/Mobile/MobileNavList';

const NavWrap = styled.div`
  padding: 48px 0 40px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileHomePageNav = () => {
  const pages = useNavLink();

  return (
    <NavWrap>
      <MobileNavList pages={pages} inPageVersion userDecisionArea="mobile homepage nav" />
    </NavWrap>
  );
};

export default MobileHomePageNav;
