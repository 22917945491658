import React, { useState } from 'react';

import styled from '../../../../lib/styled';
import { useCurrentRoute } from '../../../../lib/useCurrentRoute';
import useTracking from '../../../../lib/useTracking';
import zIndices from '../../../../lib/zIndices';
import ScreenReaderOnly from '../../../ScreenReaderOnly';
import { NavProps as DesktopNavProps } from '../Desktop';
import MobileNavList from './MobileNavList';
import MobilePageTitle from './MobilePageTitle';

interface NavProps extends DesktopNavProps {
  showMenuText?: boolean;
}
interface MobileNavWrapProps {
  open: boolean;
}
const MobileNavWrap = styled.nav<MobileNavWrapProps>`
  height: 0;
  ${(props) => props.open && 'height: 100%;'}

  background-color: ${(props) => props.theme.colors.background};
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: all 0.5s;
  width: 100%;
  z-index: ${zIndices.mobileNav};

  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileNavListWrap = styled.div`
  padding-top: 56px;
  height: 100vh;
  overflow-y: auto;
`;

const NavButtonWrap = styled.div`
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
  height: 40px;
  left: 8px;
  margin: 0;
  overflow: hidden;
  position: fixed;
  top: 8px;
  width: 40px;
  z-index: ${zIndices.mobileNavButton};
`;
interface NavButtonProps {
  open: boolean;
}

const NavBurger = styled.span<NavButtonProps>`
  :before,
  :after {
    background: ${(props) => props.theme.fonts.s5.color};
    box-shadow: 0 -10px 0 0 ${(props) => props.theme.fonts.s5.color},
      0 10px 0 0 ${(props) => props.theme.fonts.s5.color};
    content: '';
    height: 3px;
    left: 20%;
    margin: 0 auto;
    border-radius: 3px;
    margin-top: -0.15em;
    position: absolute;
    top: 50%;
    transition: all 0.4s;
    width: 24px;
  }

  :after {
    ${(props) =>
      props.open &&
      `
      box-shadow: 0 0 0 0 rgba(0,0,0,0), 0 0 0 0 rgba(0,0,0,0);
      transform: rotateZ(45deg);
    `}
  }

  ${(props) =>
    props.open &&
    `
      :before {
        box-shadow: 0 0 0 0 rgba(0,0,0,0), 0 0 0 0 rgba(0,0,0,0);
        transform: rotateZ(-45deg);
      }
    `}
`;

const NavButton = styled.button`
  background: none;
  border: none;
  font-size: 10px;
  height: 40px;
  width: 40px;
  border-radius: 3px;
  ${(props) => props.theme.components.Nav?.Button}
`;

const TitleBarWrap = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  height: 56px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${zIndices.mobileNav};

  @media (min-width: 768px) {
    display: none;
  }
`;

const LiteMobileButtonText = styled.span`
  display: inline-block;
  position: absolute;
  top: 12px;
  left: 60px;
  font-size: 18px;
  text-transform: uppercase;
  z-index: ${zIndices.mobileNavButton};
  ${({ theme }) => theme.liteSite?.Nav?.Button}
`;

export const MobileNavAndButton: React.FC<NavProps> = ({ pages, children, showMenuText }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const userDecisionArea = 'mobile nav menu';
  const currentRoute = useCurrentRoute();
  const track = useTracking('guest', currentRoute, userDecisionArea);

  const onClick = () =>
    setMobileNavOpen((prevOpen) => {
      track(`${!prevOpen ? 'open' : 'close'} menu`);

      return !prevOpen;
    });

  return (
    <>
      <NavButtonWrap>
        <NavButton onClick={onClick}>
          <NavBurger open={mobileNavOpen} />
          {!!showMenuText ? (
            <LiteMobileButtonText>Menu</LiteMobileButtonText>
          ) : (
            <ScreenReaderOnly>Menu Button</ScreenReaderOnly>
          )}
        </NavButton>
      </NavButtonWrap>
      {children}
      <MobileNavWrap open={mobileNavOpen} role="navigation">
        <MobileNavListWrap>
          <MobileNavList pages={pages} setMobileNavOpen={setMobileNavOpen} userDecisionArea={userDecisionArea} />
        </MobileNavListWrap>
      </MobileNavWrap>
    </>
  );
};

const MobileNav: React.FC<NavProps> = ({ pages }) => (
  <MobileNavAndButton pages={pages}>
    <TitleBarWrap>
      <MobilePageTitle />
    </TitleBarWrap>
  </MobileNavAndButton>
);
export default MobileNav;
