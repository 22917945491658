import React, { useContext, useEffect, useMemo, useState } from 'react';
import Cookies from 'universal-cookie';

import { Maybe, WwsPagesFragment } from '../generated/graphql';
import { isClient, isMobile } from '../lib/utils';
import { RegistryContext, WWQueryProps } from './RegistryContext';

export interface TrackingInfo {
  hasWWSAccommodations: boolean;
  hasWWSCoverPhoto: boolean;
  hasWWSFirstNames: boolean;
  hasWWSLastNames: boolean;
  hasWWSWeddingDate: boolean;
  hasWWSWeddingLocation: boolean;
  hasWWSRegistry: boolean;
  customDomain: boolean;
  rsvpOnCount: number;
  security: string;
  platform: string;
  weddingUUID?: Maybe<string>;
  ownerUUID?: Maybe<string>;
  guestUUID?: Maybe<string>;
  product: string;
  themeName?: Maybe<string>;
  themeId?: Maybe<number>;
  partner?: Maybe<string>;
}

interface Context {
  trackingInfo?: TrackingInfo;
  trackingInfoLoaded: boolean;
}

export const TrackingContext = React.createContext<Context>({
  trackingInfoLoaded: false,
});

export const TrackingConsumer = TrackingContext.Consumer;

const hasAccommodations = (pages: WwsPagesFragment['pages']): boolean =>
  !!pages?.find(({ items }) => !!items?.find(({ type }) => type === 'AccommodationItem'));

export const TrackingProvider: React.FC<WWQueryProps> = ({ children, data }) => {
  const [trackingInfo, setTrackingInfo] = useState<TrackingInfo | undefined>(undefined);
  const [trackingInfoLoaded, setTrackingInfoLoaded] = useState(false);

  const { coupleInfo } = useContext(RegistryContext);
  const cookies = isClient() ? new Cookies() : null;

  const rsvpOnCount = useMemo(() => data?.events?.filter((e) => e.requireRsvp).length || 0, [data.events]);

  useEffect(() => {
    const weddingWebsite =
      data.weddingWebsiteResult?.__typename === 'WWS_WeddingWebsiteV2' && data.weddingWebsiteResult;

    if (weddingWebsite && coupleInfo) {
      const hasWWSFirstNames = !!weddingWebsite.firstName && !!weddingWebsite.fianceFirstName;

      const hasWWSLastNames = !!weddingWebsite.lastName && !!weddingWebsite.fianceLastName;

      setTrackingInfo({
        hasWWSAccommodations: hasAccommodations(weddingWebsite.pages),
        hasWWSCoverPhoto: !!weddingWebsite.coverPhoto,
        hasWWSFirstNames,
        hasWWSLastNames,
        hasWWSWeddingDate: !!data?.wedding?.weddingDate,
        hasWWSWeddingLocation: !!weddingWebsite.weddingLocation,
        hasWWSRegistry: coupleInfo?.registries?.length > 0,
        rsvpOnCount,
        security: data.guestWedding?.isPrivateRsvp ? 'private' : 'public',
        platform: isMobile() ? 'mobile' : 'web',
        guestUUID: cookies?.get('gid'),
        ownerUUID: weddingWebsite.userUuid,
        weddingUUID: weddingWebsite.weddingUuid,
        customDomain: !!weddingWebsite.purchaseDomain,
        product: 'wedding websites',
        themeName: weddingWebsite?.theme?.name,
        themeId: weddingWebsite?.theme?.id,
        partner: weddingWebsite?.theme?.themeDesigner?.name,
      });

      setTrackingInfoLoaded(true);
    }
  }, [data, coupleInfo]);

  return <TrackingContext.Provider value={{ trackingInfo, trackingInfoLoaded }}>{children}</TrackingContext.Provider>;
};
