import { sanitize } from 'dompurify';

export const cleanUserInput = (input: string) => {
  // Don't run dompurify on server
  if (typeof window === 'undefined') {
    return input.replace(/&nbsp;/g, ' ');
  }

  return sanitize(input).replace(/&nbsp;/g, ' ');
};
