import { useContext } from 'react';

import { WWContext } from '../components/WWContext';

export const useRsvpIsVisible = (): boolean => {
  const { data } = useContext(WWContext);

  // Legacy way to check if RSVP should be shown
  const anyEventsRsvpOn = data.events.filter((e) => e.type !== 'Reception').some((e) => e.requireRsvp);

  return anyEventsRsvpOn && !data.guestWedding?.rsvpPageHidden;
};
