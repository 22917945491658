import React from 'react'
import SVGBase, { SVGComponentProps } from './SVGBase'

const ArrowRight = (props: SVGComponentProps) => {
  const { className, title = 'Arrow Right' } = props
  return (
    <SVGBase
      className={className}
      labeledBy="arrow-right-svg"
      path={<path d="M12.667 22.667l6.667-6.667-6.667-6.667z" />}
      title={title}
    />
  )
}
export default ArrowRight
