import { useRouter } from 'next/router';
import React from 'react';

import { WwsPagesFragment } from '../../../generated/graphql';
import styled from '../../../lib/styled';
import { useCurrentRoute } from '../../../lib/useCurrentRoute';
import useTracking from '../../../lib/useTracking';
import NavLink from './NavLink';

interface DesktopNavItemProps {
  handleTracking(selection: string): void;
  page: NonNullable<WwsPagesFragment['pages']>[0];
  queryParams: { [key: string]: string | string[] };
}

export interface NavProps {
  pages?: WwsPagesFragment['pages'];
}

const NavList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 8px 16px;
`;
const DesktopNavLink = styled(NavLink)`
  margin: 0;
  padding: 8px 0;
`;
const NavRow = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const NavItem = styled.li`
  display: inline-block;
  list-style: none;
  padding: 0 8px;
`;

const DesktopNavItem: React.FC<DesktopNavItemProps> = ({ page, handleTracking, queryParams }) => {
  return (
    <NavItem>
      <DesktopNavLink
        key={page.id}
        href={{
          pathname: '/[pathPrefix]/[slug]/[[route]]',
          query: {
            ...queryParams,
            route: page.routeName,
          },
        }}
        desktop
        handleTracking={handleTracking}
        title={page.title as string}
        route={page.routeName as string}
      />
    </NavItem>
  );
};

const DesktopNavWrap = styled.nav`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
  ${(props) => props.theme.components.Nav?.Desktop}
`;

const DesktopNav: React.FC<NavProps> = ({ pages }) => {
  if (!pages?.length) {
    return null;
  }

  const splitPoint = Math.ceil(pages.length / 2);
  const pathsFirstRow = pages.slice(0, splitPoint);
  const pathsSecondRow = pages.slice(splitPoint);
  const currentRoute = useCurrentRoute();
  const { query } = useRouter();

  const userDecisionArea = 'desktop nav';
  const trackGuest = useTracking('guest', currentRoute, userDecisionArea);
  const trackRsvp = useTracking('rsvp', currentRoute, userDecisionArea);

  const handleTracking = (selection: string) => {
    if (selection === 'rsvp') {
      trackRsvp(selection, '');
    }

    trackGuest(selection, '');
  };

  return (
    <DesktopNavWrap>
      <NavList>
        <NavRow data-testid="desktop-nav-row-1">
          {pathsFirstRow.map((p) => (
            <DesktopNavItem key={p.id} page={p} handleTracking={handleTracking} queryParams={query} />
          ))}
        </NavRow>
        <NavRow data-testid="desktop-nav-row-2">
          {pathsSecondRow.map((p) => (
            <DesktopNavItem key={p.id} page={p} handleTracking={handleTracking} queryParams={query} />
          ))}
        </NavRow>
      </NavList>
    </DesktopNavWrap>
  );
};
export default DesktopNav;
