import format from 'date-fns/format';
import parse from 'date-fns/parse';
import React from 'react';

import { PhotoTimelineItemFragment } from '../../generated/graphql';
import { extractParamsFromMediaUrl } from '../../lib/mediaApiUrl';
import styled from '../../lib/styled';
import { themeFont } from '../../lib/themeFont';
import { BaseItemProps } from '../PageContents';
import Picture from '../Picture';

const GalleryWrap = styled.div`
  display: flex;
  margin: 24px auto 80px auto;
  max-width: 1000px;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  &::first-child {
    padding-top: 3rem;
  }
`;

const TimelinePhoto = styled.figure`
  margin: 0;
  padding-left: 3rem;
  padding-right: 3rem;
  flex: 1;
  z-index: 1;
  img {
    width: 100%;
    display: block;
    height: auto;
  }
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: ${({ theme }): string => theme.colors.background};
  @media (min-width: 540px) {
    padding-top: inherit;
    padding-bottom: inherit;
    background: transparent;
  }
`;

const TimelineDetails = styled.div`
  margin: 0;
  flex: 1;
  padding-left: 3rem;
  padding-right: 3rem;
  ${themeFont('s6')}
  text-align: center;
  background: ${({ theme }): string => theme.colors.background};
  z-index: 1;
  padding-bottom: 1rem;
  @media (min-width: 540px) {
    text-align: inherit;
    padding-bottom: inherit;
    background: transparent;
  }
`;

const TimelineDate = styled.h2`
  font-weight: bold;
  ${themeFont('s4')}
`;

const GalleryItem = styled.div`
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  max-width: 100vw;
  @media (min-width: 540px) {
    max-width: inherit;
    flex-direction: row;
    &:nth-child(odd) {
      flex-direction: row-reverse;
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      display: block;
      border-left: 1px dashed ${({ theme }): string => theme.fonts.s4.color};
      height: calc(50% - 0.6rem);
      bottom: 0;
      transform: translate(-50%, 0%);
      ${({ theme }) => theme.components.TimelineLineFlourish}
    }

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      display: block;
      top: 0;
      height: calc(50% - 0.6rem);
      border-left: 1px dashed ${({ theme }): string => theme.fonts.s4.color};
      transform: translate(-50%, 0%);
      ${({ theme }) => theme.components.TimelineLineFlourish}
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    display: block;
    top: 0;
    bottom: 0;
    border-left: 1px dashed ${({ theme }): string => theme.fonts.s4.color};
    transform: translate(-50%, 0%);
    ${({ theme }) => theme.components.TimelineLineFlourish}
  }
`;

const Dot = styled.div`
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${({ theme }): string =>
    theme.components.TimelineDotFlourish ? 'transparent' : theme.fonts.s4.color};
  border-radius: ${({ theme }) => (theme.components.TimelineDotFlourish ? 'inherit' : '100rem')};
  transform: translate(-50%, -50%);
  ${({ theme }) => theme.components.TimelineDotFlourish}
`;

type PhotoItemProps = PhotoTimelineItemFragment['photoItems'][0];

const comparePhotos = (firstEl: PhotoItemProps, secondEl: PhotoItemProps) => {
  const firstElDate = firstEl.date || '';
  const secondElDate = secondEl.date || '';
  if (firstElDate < secondElDate) {
    return -1;
  }

  if (firstElDate > secondElDate) {
    return 1;
  }

  return 0;
};

const PhotoTimelineItem: React.FC<BaseItemProps> = ({ item, className }) => {
  const { photoItems, id } = item as PhotoTimelineItemFragment;

  return (
    <GalleryWrap className={className} data-testid={`photo-timeline-item-${id}`}>
      {/* .sort is mutatating operation it changes the orignal object which apollo no likey */}
      {[...photoItems].sort(comparePhotos).map((photo, index) => {
        const { crop } = extractParamsFromMediaUrl(photo.mediaUrl);
        return (
          <GalleryItem key={`photoTimeline-${photo.id}`} data-testid={`photo-timeline-row-${index}`}>
            <TimelinePhoto>
              <Picture url={photo.mediaUrl} alt={photo.caption || 'Memory'} crop={crop} />
            </TimelinePhoto>
            <TimelineDetails>
              {photo.date && <TimelineDate>{format(parse(photo.date), 'MMMM DD, YYYY')}</TimelineDate>}
              {photo.caption && <div>{photo.caption}</div>}
            </TimelineDetails>
            <Dot />
          </GalleryItem>
        );
      })}
    </GalleryWrap>
  );
};

export default PhotoTimelineItem;
