import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import { useWeddingWebsiteQueryLazyQuery } from '../generated/graphql';
import styled from '../lib/styled';
import { themeFont } from '../lib/themeFont';
import ActionButton from './ActionButton';

const Center = styled.div`
  text-align: center;
`;
const Title = styled.h4`
  color: rgb(31, 31, 31);
  line-height: 1;
  margin: 24px 0 0;
  ${themeFont('s4')}
`;
const Fields = styled.div`
  display: flex;
  justify-content: center;
`;
const LoginForm = styled.form`
  margin: 24px auto;
  width: fit-content;
  text-align: center;
  display: flex;
  flex-direction: column;
`;
const SubmitButton = styled(ActionButton)`
  width: fit-content;
  margin: 12px;
`;
const Input = styled.input`
  ${themeFont('s6')}
  color: black;
  padding: 12px;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 12px;
  overflow: visible;
  border: 1px solid ${(props) => props.theme.fonts.s6.color};
  border-radius: 2px;

  ::placeholder {
    ${themeFont('s6')}
    color: #000;
  }

  ::-webkit-input-placeholder {
    ${themeFont('s6')}
    color: #000;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    ${themeFont('s6')}
    color: #000;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    ${themeFont('s6')}
    color: #000;
  }

  :-ms-input-placeholder {
    ${themeFont('s6')}
    color: #000;
  }
`;
interface ErrorProps {
  error: string | null;
}
const Error = styled.div<ErrorProps>`
  padding: 12px;
  margin: 12px;
  ${themeFont('s5')};
  background-color: ${(props) => props.theme.colors.alert};
  opacity: 0;
  ${(props) => props.error && 'opacity: 1;'}
  transition: all 0.5s ease-out;
`;

const Login: React.FC = ({}) => {
  const [pin, setPin] = useState<string | undefined>('');
  const [error, setError] = useState<string | null>(null);
  const [getLogin, { data, loading }] = useWeddingWebsiteQueryLazyQuery();
  const { query } = useRouter();

  useEffect(() => {
    if (!loading) {
      if (
        data?.weddingWebsiteResult?.__typename === 'WWS_Error' &&
        data.weddingWebsiteResult.cause === 'PIN_REQUIRED'
      ) {
        setError('Incorrect Password');
        document.cookie = `wws-pin= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
      } else if (data?.weddingWebsiteResult?.__typename === 'WWS_WeddingWebsiteV2') {
        document.cookie = `wws-pin=${pin}`;
        // Reloading is kinda weird better would be to clear cache for error state
        window.location.reload();
      }
    }
  }, [loading, data, pin]);

  // eslint-disable-next-line consistent-return
  const login = async (evt: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLAnchorElement>) => {
    evt.preventDefault();
    try {
      // Context is required so it's passed as header
      await getLogin({ variables: { slug: query.slug as string, pin }, context: { slug: query.slug as string } });
    } catch (e) {
      return null;
    }
  };

  return (
    <>
      <Center>
        <Title>Enter password to view this site</Title>
        <LoginForm onSubmit={login}>
          <Fields>
            <Input
              placeholder="Password"
              type="password"
              onChange={(evt) => {
                setError(null);
                setPin(evt.target.value);
              }}
              value={pin}
            />
            <SubmitButton type="submit" onClick={login}>
              Submit
            </SubmitButton>
          </Fields>
          <Error error={error}>{error}</Error>
        </LoginForm>
      </Center>
    </>
  );
};

export default Login;
