import React, { useContext } from 'react';

import { EventsFragment, SubEventFragment } from '../../../generated/graphql';
import { cleanUserInput } from '../../../lib/cleanUserInput';
import { colorAlpha } from '../../../lib/colorAlpha';
import getEventDateTime from '../../../lib/getEventDateTime';
import styled from '../../../lib/styled';
import { themeFont } from '../../../lib/themeFont';
import { WWContext } from '../../WWContext';
import EventTime from './EventTime';
import ScheduleSubEvent from './ScheduleSubEvent';

const EventWrap = styled.div`
  margin: auto;
  max-width: 720px;
  padding: 0 20px;
  @media (min-width: 720px) {
    padding: 0;
  }
  ${(props) => props.theme.components.Event}
`;
const DetailsWrap = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 48px 0 24px;
  text-align: center;
`;
const SubEventsWrap = styled.div`
  ${(props) => `border-top: 1px solid ${colorAlpha(props.theme.fonts.s6.color, '15%')};`}
`;
const Name = styled.h4`
  line-height: 1;
  ${themeFont('s3')}
  margin: 32px 0 8px 0;
`;
const Grouping = styled.div`
  margin: 8px 0;
`;
const Detail = styled.div`
  ${themeFont('s6')}
  margin: 4px 0;
`;
const DateTime = styled.div`
  ${themeFont('s4')}
  margin: 8px 0;
`;
const Description = styled.div`
  ${themeFont('s6')}
  margin: 8px 0;
  max-width: 460px;
  white-space: pre-line;
`;

interface Props {
  event: EventsFragment;
}

const ScheduleEvent: React.FC<Props> = ({ event }) => {
  const { date, notes, endTime, location, name, subEvents, time, visible, attire, type } = event;
  const { data } = useContext(WWContext);
  const { hideDate, weddingDateFormat } = data.weddingWebsiteResult;

  const isWeddingDayEvent = type === 'WeddingDay';
  const wordyFormat = isWeddingDayEvent && ['ornate', 'verbose'].includes(weddingDateFormat || '');
  const { date: formattedDate, time: formattedTime, endTime: formattedEndTime } = getEventDateTime({
    date,
    endTime,
    isWeddingDayEvent,
    time,
    weddingDateFormat,
  });

  const additionalInfoFields: Array<keyof SubEventFragment> = ['attire', 'endTime', 'location', 'notes', 'time'];
  // Show subEvents only if they are visible and have at least one piece of user-added data
  const visibleSubEvents = subEvents
    ?.filter((e) => e.visible)
    .filter((subEvent) => additionalInfoFields.some((field) => subEvent[field]));

  const requiredProps = [name];
  const requiredPropsMissing = requiredProps.some((p) => !p);
  if (requiredPropsMissing || !visible) {
    return null;
  }

  return (
    <EventWrap>
      <DetailsWrap>
        <Grouping>
          <Name>{name}</Name>
          {isWeddingDayEvent && hideDate ? (
            <Detail>Date To Be Announced</Detail>
          ) : (
            <>
              {formattedDate && <DateTime>{formattedDate}</DateTime>}
              {!wordyFormat && (
                <DateTime>
                  <EventTime time={formattedTime} endTime={formattedEndTime} />
                </DateTime>
              )}
            </>
          )}
        </Grouping>
        {(location?.name || location?.fullAddress) && (
          <Grouping>
            {location?.name && <Detail>{location.name}</Detail>}
            {location?.fullAddress && <Detail>{location.fullAddress}</Detail>}
          </Grouping>
        )}
        {attire && <Detail>{`Attire: ${attire}`}</Detail>}
        {notes && (
          <Grouping>
            <Description dangerouslySetInnerHTML={{ __html: cleanUserInput(notes) }} />
          </Grouping>
        )}
      </DetailsWrap>
      {!!visibleSubEvents?.length && (
        <SubEventsWrap>
          {visibleSubEvents.map((subEvent) => (
            <ScheduleSubEvent {...subEvent} key={subEvent.id} />
          ))}
        </SubEventsWrap>
      )}
    </EventWrap>
  );
};
export default ScheduleEvent;
