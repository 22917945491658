import { css } from '@emotion/core';

import mediaApiUrl, { extractParamsFromMediaUrl } from './mediaApiUrl';

interface ResponsiveBackgroundImageProps {
  resizeDimension?: 'height' | 'width';
  url?: string;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  responsiveImages?: {
    smUrl?: string;
    mdUrl?: string;
    lgUrl?: string;
    xlUrl?: string;
  };
}
export const responsiveBackgroundImage = (props: ResponsiveBackgroundImageProps) => {
  // sm: > 0px
  // md: > 768px
  // lg: > 1024px
  // xl: > 1280px
  // + @media selectors for 1.5x and 2x pixel density devices

  const { resizeDimension = 'width', lg, md, sm, url: fullUrl, xl, responsiveImages = {} } = props;
  if (!fullUrl) {
    return '';
  }
  const { url, format } = extractParamsFromMediaUrl(fullUrl);
  const { smUrl = url, mdUrl = url, lgUrl = url, xlUrl = url } = responsiveImages;
  return css`
    // any pixel density (1x)
    background-image: url(${mediaApiUrl({
      url: smUrl,
      format,
      [resizeDimension]: sm,
    })});

    @media (min-width: 768px) {
      background-image: url(${mediaApiUrl({
        url: mdUrl,
        format,
        [resizeDimension]: md,
      })});
    }
    @media (min-width: 1024px) {
      background-image: url(${mediaApiUrl({
        url: lgUrl,
        format,
        [resizeDimension]: lg,
      })});
    }
    @media (min-width: 1280px) {
      background-image: url(${mediaApiUrl({
        url: xlUrl,
        format,
        [resizeDimension]: xl,
      })});
    }

    // >1.5x pixel density
    @media (min-resolution: 144dpi), (-webkit-min-device-pixel-ratio: 1.5) {
      background-image: url(${mediaApiUrl({
        url: smUrl,
        format,
        [resizeDimension]: sm * 1.5,
      })});
    }
    @media (min-resolution: 144dpi) and (min-width: 768px),
      (-webkit-min-device-pixel-ratio: 1.5) and (min-width: 768px) {
      background-image: url(${mediaApiUrl({
        url: mdUrl,
        format,
        [resizeDimension]: md * 1.5,
      })});
    }
    @media (min-resolution: 144dpi) and (min-width: 1024px),
      (-webkit-min-device-pixel-ratio: 1.5) and (min-width: 1024px) {
      background-image: url(${mediaApiUrl({
        url: lgUrl,
        format,
        [resizeDimension]: lg * 1.5,
      })});
    }
    @media (min-resolution: 144dpi) and (min-width: 1280px),
      (-webkit-min-device-pixel-ratio: 1.5) and (min-width: 1280px) {
      background-image: url(${mediaApiUrl({
        url: xlUrl,
        format,
        [resizeDimension]: xl * 1.5,
      })});
    }

    // >2x pixel density
    @media (min-resolution: 192dpi), (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(${mediaApiUrl({
        url: smUrl,
        format,
        [resizeDimension]: sm * 2,
      })});
    }
    @media (min-resolution: 192dpi) and (min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 768px) {
      background-image: url(${mediaApiUrl({
        url: mdUrl,
        format,
        [resizeDimension]: md * 2,
      })});
    }
    @media (min-resolution: 192dpi) and (min-width: 1024px),
      (-webkit-min-device-pixel-ratio: 2) and (min-width: 1024px) {
      background-image: url(${mediaApiUrl({
        url: lgUrl,
        format,
        [resizeDimension]: lg * 2,
      })});
    }
    @media (min-resolution: 192dpi) and (min-width: 1280px),
      (-webkit-min-device-pixel-ratio: 2) and (min-width: 1280px) {
      background-image: url(${mediaApiUrl({
        url: xlUrl,
        format,
        [resizeDimension]: xl * 2,
      })});
    }
  `;
};
export default responsiveBackgroundImage;
