import { isEmpty, isNil } from 'lodash';
import React, { useContext } from 'react';

import styled from '../../../lib/styled';
import { themeFont } from '../../../lib/themeFont';
import useDateDisplayWithFormat from '../../../lib/useDateDisplayWithFormat';
import zIndices from '../../../lib/zIndices';
import { WWContext } from '../../WWContext';
import dateCountdown from './dateCountdown';

const textShadow = 'text-shadow: 0 0 50px hsla(0,0%,0%,1);';

const SiteTitle = styled.h1<HeaderProps>`
  line-height: 1;
  ${themeFont('s1')}
  margin: 16px 0;
  // Title is white when over hero photo
  ${(props) => props.heroVersion && `${textShadow} color: white;`}
  ${(props) => props.theme.components.SiteTitle}
`;
const SiteSubtitle = styled.div<HeaderProps>`
  line-height: 1;
  ${themeFont('s5')}
  margin: 8px 0;
  ${(props) => props.heroVersion && `${textShadow} color: white;`}
  ${(props) => props.theme.components.SiteSubtitle}
`;
const HeaderWrap = styled.header`
  padding: 0 4% 10px 4%;
  text-align: center;
  z-index: ${zIndices.header};
`;

interface HeaderProps {
  className?: string;
  heroVersion: boolean;
}
const Header = (props: HeaderProps) => {
  const { className, heroVersion } = props;
  const { data } = useContext(WWContext);
  const {
    firstName,
    fianceFirstName,
    hideCountdown = false,
    weddingDateFormat,
    weddingDateRangeStart,
    weddingLocation,
    hideDate,
  } = data.weddingWebsiteResult;

  const differenceDays = dateCountdown({
    hideDate,
    weddingDate: data.wedding?.weddingDate,
    weddingDateFormat,
    weddingDateRangeStart,
  });
  const formattedDate = useDateDisplayWithFormat();
  const dateLocation = [formattedDate, weddingLocation].filter((v) => !isNil(v) && !isEmpty(v)).join(' • ');

  return (
    <HeaderWrap className={className}>
      <>
        {firstName && fianceFirstName && (
          <SiteTitle data-testid="header-couple-names" heroVersion={heroVersion}>
            {`${firstName} & ${fianceFirstName}`}
          </SiteTitle>
        )}
        <SiteSubtitle data-testid="header-wedding-date-location" heroVersion={heroVersion}>
          {dateLocation}
        </SiteSubtitle>
        {differenceDays && differenceDays > 0 && !hideCountdown ? (
          <SiteSubtitle data-testid="header-countdown" heroVersion={heroVersion}>
            {`${differenceDays} Days To Go!`}
          </SiteSubtitle>
        ) : null}
      </>
    </HeaderWrap>
  );
};

export default Header;
