import { useRouter } from 'next/router';
import React from 'react';

import { colorAlpha } from '../../../../lib/colorAlpha';
import styled from '../../../../lib/styled';
import { useCurrentRoute } from '../../../../lib/useCurrentRoute';
import useTracking from '../../../../lib/useTracking';
import { NavProps } from '../Desktop';
import NavLink from '../NavLink';

interface ListWrapProps {
  inPageVersion: boolean;
}
const ListWrap = styled.ul<ListWrapProps>`
  ${(props) => props.inPageVersion && `border-top: 1px solid ${colorAlpha(props.theme.fonts.s5.color, '45%')};`}
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;

  ${(props) => props.theme.components.Nav?.Mobile}
`;
const MobileNavItem = styled.li<ListWrapProps>`
  ${(props) => props.inPageVersion && `border-bottom: 1px solid ${colorAlpha(props.theme.fonts.s5.color, '45%')};`}
  text-align: center;
  width: 100%;
  padding: 8px;

  :hover {
    background-color: ${(props) => colorAlpha(props.theme.fonts.s5.color, '10%')};
  }
  ${(props) => props.theme.components.Nav?.Item}
`;
interface MobileNavListProps extends NavProps {
  inPageVersion?: boolean;
  setMobileNavOpen?: (open: boolean) => void;
  userDecisionArea: string;
}

const MobileNavList: React.FC<MobileNavListProps> = ({
  inPageVersion = false,
  pages,
  setMobileNavOpen,
  userDecisionArea,
}) => {
  const currentRoute = useCurrentRoute();
  const trackGuest = useTracking('guest', currentRoute, userDecisionArea);
  const trackRsvp = useTracking('rsvp', currentRoute, userDecisionArea);
  const { query } = useRouter();

  const handleTracking = (selection: string) => {
    if (selection === 'rsvp') {
      trackRsvp(selection);
    }

    trackGuest(selection);
  };

  if (!pages?.length) {
    return null;
  }

  return (
    <ListWrap inPageVersion={inPageVersion}>
      {pages.map((page) => (
        <MobileNavItem inPageVersion={inPageVersion} key={page.id}>
          <NavLink
            href={{
              pathname: '/[pathPrefix]/[slug]/[[route]]',
              query: {
                ...query,
                route: page.routeName,
              },
            }}
            title={page.title as string}
            route={page.routeName as string}
            setMobileNavOpen={setMobileNavOpen}
            handleTracking={handleTracking}
          />
        </MobileNavItem>
      ))}
    </ListWrap>
  );
};
export default MobileNavList;
