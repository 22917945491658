import { colorAlpha } from '../lib/colorAlpha';
import styled from '../lib/styled';
import { themeFont } from '../lib/themeFont';

const ActionButton = styled.a`
  letter-spacing: 1px;
  line-height: 1.25;
  ${themeFont('s6')}

  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
  padding: 13px 36px;
  text-decoration: none;
  transition: all 0.25s;
  background-color: ${(props) => props.theme.fonts.s4.color};
  color: ${(props) => props.theme.colors.background};
  ${(props) => props.theme.components.Button} :hover {
    background-color: ${({ theme }) =>
      colorAlpha(theme.components.Button?.backgroundColor || theme.fonts.s4.color, '70%')};
    ${(props) => props.theme.components.ButtonHover}
  }
`;

export default ActionButton;
