import React from 'react';

import { LivestreamItemFragment } from '../../generated/graphql';
import styled from '../../lib/styled';
import ActionButton from '../ActionButton';
import { BaseItemProps } from '../PageContents';
import { Item, ItemDescription } from './BusinessItem';
import { H3 } from './HeadlineItem';

const Button = styled(ActionButton)`
  width: fit-content;
  margin: 0 auto;
`;

const LivestreamItem: React.FC<BaseItemProps> = ({ item, className }) => {
  const { id, title, description, buttonUrl, buttonText } = item as LivestreamItemFragment;
  return (
    <Item className={className} data-testid={`livestream-item-${id}`}>
      <H3>{title}</H3>
      <ItemDescription>{description}</ItemDescription>
      <Button target="_blank" rel="noopener noreferrer" href={buttonUrl || ''}>
        {buttonText}
      </Button>
    </Item>
  );
};
export default LivestreamItem;
