import React from 'react';

import { PhotoGalleryItemFragment } from '../../generated/graphql';
import styled from '../../lib/styled';
import { BaseItemProps } from '../PageContents';
import Photo from '../Photo';

const GalleryWrap = styled.div`
  display: flex;
  margin: 24px auto 80px auto;
  width: 320px;
  @media (min-width: 670px) {
    width: 635px;
  }
  @media (min-width: 995px) {
    width: 960px;
  }
  flex-wrap: wrap;
  ${(props) => props.theme.components.PhotoGallery}
`;

const PhotoGalleryItem: React.FC<BaseItemProps> = ({ item, className }) => {
  const { photoItems, id } = item as PhotoGalleryItemFragment;
  return (
    <GalleryWrap className={className} data-testid={`photo-gallery-item-${id}`}>
      {photoItems.map((photo, index) => {
        const { id: prevPhotoId } = photoItems[index - 1] || {};
        const { id: nextPhotoId } = photoItems[index + 1] || {};
        const { width, height, rotation, mediaUrl } = photo;
        let caption;
        const cropX = photo?.cropX || 0;
        const cropY = photo?.cropY || 0;
        const crop = `${cropX}.${cropY}.${cropX + width}.${cropY + height}`;

        return (
          <Photo
            alt={`Gallery Photo ${index + 1}`}
            caption={caption}
            id={photo.id}
            key={photo.id}
            crop={crop}
            nextPhotoId={nextPhotoId}
            path={mediaUrl}
            prevPhotoId={prevPhotoId}
            rotation={rotation || 0}
            dimensions={{
              width,
              height,
            }}
          />
        );
      })}
    </GalleryWrap>
  );
};

export default PhotoGalleryItem;
