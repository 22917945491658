import isEmpty from 'lodash/isEmpty';
import { useContext } from 'react';
import { WWContext } from '../components/WWContext';
import dateRangeFormatted from './dateFormatter/dateRangeFormatted';
import dateTimeFormatted from './dateFormatter/dateTimeFormatted';

export const TBD_MESSAGE = 'Date To Be Announced';

export enum SPECIFY_FORMAT {
  NATURAL = 'natural',
  SHORT = 'short',
}

// Cascading Priority
// 1. hideData
// 2. no data
// 3. multi date range
// 4. wedding date
// 5. season year

const useDateDisplayWithFormat = (specifyFormat?: SPECIFY_FORMAT): string | null => {
  const { data } = useContext(WWContext);
  const weddingDate = data?.wedding?.weddingDate;
  const season = data?.wedding?.season;
  const weddingDateFormat = data?.weddingWebsiteResult?.weddingDateFormat;
  const weddingDateRangeStart = data?.weddingWebsiteResult?.weddingDateRangeStart;
  const weddingDateRangeEnd = data?.weddingWebsiteResult?.weddingDateRangeEnd;
  const hideDate = data?.weddingWebsiteResult?.hideDate;

  // HIDE DATE
  if (hideDate) return TBD_MESSAGE;

  // NOT SURE YET: no data
  if (isEmpty(season) && isEmpty(weddingDate) && isEmpty(weddingDateRangeStart) && isEmpty(weddingDateRangeEnd))
    return null;

  // MULTI DATE RANGE
  if (weddingDateFormat === 'range' && weddingDateRangeStart) {
    if (specifyFormat === SPECIFY_FORMAT.SHORT) {
      return dateRangeFormatted(weddingDateRangeStart, weddingDateRangeEnd, { month: 'numeric' });
    }
    return dateRangeFormatted(weddingDateRangeStart, weddingDateRangeEnd);
  }

  // EXACT DATE WEDDING DATE
  if (weddingDate) {
    const isWordyFormat = ['verbose', 'ornate'].includes(weddingDateFormat || '');
    const format = isWordyFormat ? `${weddingDateFormat}DateOnly` : weddingDateFormat;
    const theFormat = specifyFormat || format;
    return dateTimeFormatted(weddingDate, theFormat);
  }

  // SEASON YEAR
  if (season?.name && season?.year) {
    return `${season.name} ${season.year}`;
  }

  return null;
};

export default useDateDisplayWithFormat;
