import { useEffect } from 'react';
import LazyLoad from 'vanilla-lazyload';

import { isClient } from '../lib/utils';

const LazyLoadImages = () => {
  useEffect(() => {
    if (isClient() && !(document as any).lazyLoadInstance) {
      (document as any).lazyLoadInstance = new LazyLoad({
        /* eslint-disable @typescript-eslint/camelcase */
        elements_selector: '[loading=lazy]',
        load_delay: 100,
        /* eslint-enable @typescript-eslint/camelcase */
      });
    }
  }, []);

  return null;
};

export default LazyLoadImages;
