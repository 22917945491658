import React from 'react';

import { useNavLink } from '../../../lib/useNavLinks';
import DesktopNav from './Desktop';
import MobileNav from './Mobile';

const Nav = () => {
  const pages = useNavLink();

  return (
    <>
      <MobileNav pages={pages} />
      <DesktopNav pages={pages} />
    </>
  );
};
export default Nav;
