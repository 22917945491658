import React from 'react';

import { PhotoAlbumsFragment } from '../../generated/graphql';
import styled from '../../lib/styled';
import Photo from '../Photo';

const AlbumWrap = styled.div`
  display: flex;
  margin: 24px auto 80px auto;
  width: 320px;
  @media (min-width: 670px) {
    width: 635px;
  }
  @media (min-width: 995px) {
    width: 960px;
  }
  flex-wrap: wrap;
  ${(props) => props.theme.components.PhotoGallery}
`;

interface Props {
  photoAlbums: PhotoAlbumsFragment['photoAlbums'];
}

const PhotoAlbum: React.FC<Props> = ({ photoAlbums }) => {
  if (!photoAlbums?.length) {
    return null;
  }
  return (
    <>
      {photoAlbums.map((album, i) => {
        if (album.images) {
          return (
            <AlbumWrap key={album.id || i}>
              {album.images.map((image, index) => {
                const prevPhotoId = (album.images && album.images[index - 1]?.id) || undefined;
                const nextPhotoId = (album.images && album.images[index + 1]?.id) || undefined;

                return (
                  <Photo
                    alt={`Album Photo ${index + 1}`}
                    caption={image.caption || ''}
                    id={image.id || index}
                    key={image.id}
                    nextPhotoId={nextPhotoId}
                    path={image.galleryPath || ''}
                    prevPhotoId={prevPhotoId}
                  />
                );
              })}
            </AlbumWrap>
          );
        }

        return null;
      })}
    </>
  );
};

export default PhotoAlbum;
