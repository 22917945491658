import React from 'react';

import { Maybe } from '../../../generated/graphql';
import { getInitials } from '../../../lib/getInitials';
import styled from '../../../lib/styled';
import { themeFont } from '../../../lib/themeFont';
import useDateDisplayWithFormat, { SPECIFY_FORMAT } from '../../../lib/useDateDisplayWithFormat';

const LogoWrap = styled.div`
  letter-spacing: 3.4px;
  line-height: 1;
  margin: 52px auto;
  text-align: center;
  width: fit-content;
  ${(props) => props.theme.components.InitialsDateLockup}
`;
const Initials = styled.div`
  ${themeFont('s1')}
  padding: 0 18px 16px;
`;
const LogoDate = styled.div`
  border-top: 1px solid
    ${(props) => props.theme.components.InitialsDateLockup?.borderColor || props.theme.fonts.s1.color};
  ${themeFont('s5')}
  padding: 20px 18px 0;
`;
interface InitialsDateLockupProps {
  firstName?: Maybe<string>;
  fianceFirstName?: Maybe<string>;
}
const InitialsDateLockup: React.FC<InitialsDateLockupProps> = ({ firstName, fianceFirstName }) => {
  const initials = getInitials({ firstName, fianceFirstName });

  if (!initials) return null;

  const formattedDate = useDateDisplayWithFormat(SPECIFY_FORMAT.SHORT);

  return (
    <LogoWrap>
      <Initials>{initials}</Initials>
      {formattedDate && <LogoDate>{formattedDate}</LogoDate>}
    </LogoWrap>
  );
};

export default InitialsDateLockup;
