import React from 'react';

import { HeadlineItemFragment } from '../../generated/graphql';
import styled from '../../lib/styled';
import { themeFont } from '../../lib/themeFont';
import { BaseItemProps } from '../PageContents';

export const H3 = styled.h3`
  margin: 44px 0 0 0;
  text-align: center;
  ${themeFont('s3')}

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const H4 = styled.h4`
  margin: 24px 0 0 0;
  text-align: center;
  ${themeFont('s4')}

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

const HeadlineItem: React.FC<BaseItemProps> = ({ className = '', item }) => {
  const { title, style = 'large', id } = item as HeadlineItemFragment;

  let Component;
  if (style === 'large') {
    Component = H3;
  } else {
    Component = H4;
  }

  return (
    <Component className={className} data-testid={`headline-item-${id}`}>
      {title}
    </Component>
  );
};

export default HeadlineItem;
