import { Theme } from '../../lib/styled';

const fontWeightConversions = {
  light: '300',
  normal: '400',
  semibold: '600',
  bold: '700',
};

type Weights = keyof typeof fontWeightConversions;

const convertFontWeight = (weight: Weights): string =>
  Object.keys(fontWeightConversions).includes(weight) ? fontWeightConversions[weight as Weights] : weight;

const googleFontQuery = (fontsObjects: Theme['fonts']): string[] | null => {
  const fonts = Object.values(fontsObjects);
  const distinctFontFamilies = [...Array.from(new Set(fonts.filter((f) => f.fontFamily).map((f) => f.fontFamily)))];
  const filteredFamilies = distinctFontFamilies.filter(
    (fam) => !['Arial', 'Courier', 'Georgia', 'Lucida Sans', 'Times New Roman', 'Trebuchet MS'].includes(fam)
  );
  // If no google font's return null
  if (filteredFamilies.length === 0) {
    return null;
  }

  const fontWeights = filteredFamilies.map((family) => {
    const weights = fonts
      .filter((f) => f.fontFamily === family)
      .map((f) => f.fontWeight as Weights)
      .map(convertFontWeight);
    const weightString = [...Array.from(new Set(weights))].join(',');
    return `${family}:${weightString}`;
  });

  const query = fontWeights.map((s) => s.replace(/\s/g, '+').replace(/%20/g, '+').replace(/'/g, ''));
  const lastIndex = query.length - 1;
  query[lastIndex] = `${query[lastIndex]}&display=swap`;

  return query;
};

export default googleFontQuery;
