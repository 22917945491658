import dateTimeFormatted from './dateFormatter/dateTimeFormatted';

interface Params {
  date?: string | null;
  endTime?: string | null;
  isWeddingDayEvent?: boolean;
  time?: string | null;
  weddingDateFormat?: string | null;
}

function timeToLocaleString(time?: string | null) {
  if (!time) return '';

  const timeDate = new Date(`2022/1/1 ${time} UTC`); // we only care about the time portion
  const formattedTime = dateTimeFormatted(timeDate, 'hoursMinutes');

  return formattedTime;
}

const getEventDateTime = (props: Params): Params => {
  const { date, endTime, isWeddingDayEvent, time, weddingDateFormat = 'weekday' } = props;
  let format = weddingDateFormat;
  if (format === 'range' || !isWeddingDayEvent) format = 'weekday';

  const dateWithTime = time && date ? `${date} ${time} UTC` : date;

  const formattedDate = dateTimeFormatted(dateWithTime, format);
  const formattedTime = timeToLocaleString(time);
  const formattedEndTime = timeToLocaleString(endTime);

  return {
    date: formattedDate,
    time: formattedTime,
    endTime: formattedEndTime,
  };
};

export default getEventDateTime;
