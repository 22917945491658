import { useTheme } from 'emotion-theming';
import Head from 'next/head';
import React, { ReactNode } from 'react';

import styled, { Theme } from '../../lib/styled';
import Picture from '../Picture';
import Background from './Background';
import GoogleFonts from './GoogleFonts';

const Card = styled.div`
  ${(props) => props.theme.components.Card}
`;

const Page = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.components.Page}
`;
const Footer = styled.footer`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: auto;
  position: relative;
  width: 100%;

  ${(props) => props.theme.components.Footer}
`;
const BottomImage = styled(Picture)`
  width: 100%;
  max-height: 120px;
  ${(props) => props.theme.components.BottomImage}
`;

interface ThemeProps {
  children: ReactNode;
  footer?: ReactNode;
  offsetBackgroundForNavBar?: boolean;
}

const ThemeWrap: React.FC<ThemeProps> = ({ children, footer, offsetBackgroundForNavBar = true }) => {
  const theme: Theme = useTheme();
  const { bottom } = theme.images;
  const { bottom: bottomResponsive } = theme.responsiveImages || {};

  return (
    <Card>
      <Head>
        <link
          rel="icon"
          type="image/x-icon"
          href={`${process.env.ASSET_URL ? process.env.ASSET_URL : ''}/favicon.ico`}
        />
      </Head>
      <GoogleFonts />
      <Background offsetBackgroundForNavBar={offsetBackgroundForNavBar} />
      <Page>{children}</Page>
      <Footer>
        {footer}

        <BottomImage url={bottom} placeholderWidth={100} responsiveImages={bottomResponsive} alt="" />
      </Footer>
    </Card>
  );
};
export default ThemeWrap;
