import { useTheme } from 'emotion-theming';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';

import styled, { Theme } from '../../lib/styled';
import Picture from '../Picture';
import { WWContext } from '../WWContext';
import Announcement from './Announcement';
import Header from './Header';
import HeroImage from './HeroImage';
import Nav from './Nav';

const MobileTitleBarOffset = styled.div`
  height: 56px;
  width: 100%;

  @media (min-width: 768px) {
    display: none;
  }
`;
const TopImage = styled(Picture)`
  max-width: 100%;
  max-height: 120px;
  margin-bottom: 20px;
  width: 100%;
  ${({ theme }) => theme.components.TopImage}
`;
const mobileOnly = (component: React.FC<any>) =>
  styled(component)`
    @media (min-width: 768px) {
      display: none;
    }
  `;
const MobileAnnouncement = mobileOnly(Announcement);
const MobileTopImage = mobileOnly(TopImage);
const MobileHeroImage = mobileOnly(HeroImage);
const MobileHeader = mobileOnly(Header);

const desktopOnly = (component: React.FC<any>) =>
  styled(component)`
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  `;
const DesktopAnnouncement = desktopOnly(Announcement);
const DesktopTopImage = desktopOnly(TopImage);
const DesktopHeroImage = desktopOnly(HeroImage);
const DesktopHeader = desktopOnly(Header);
const HeroSpacer = styled.div`
  height: 20px;
  width: 100%;
`;
const TopMatter: React.FC = () => {
  const theme: Theme = useTheme();
  const { query } = useRouter();
  const { data } = useContext(WWContext);

  const { route = [] } = query || {};
  const { announcement, coverPhoto } = data.weddingWebsiteResult;

  const { headerOnHero } = theme.config;
  const { top } = theme.images;
  const { top: topResponsive } = theme.responsiveImages || {};

  let hero;
  const isHomePage = !route.length;

  if (isHomePage) {
    hero = coverPhoto?.path;
  }
  const landscapeCrop = coverPhoto?.cropBox;
  const displayHeaderOnHero = (hero && headerOnHero) || false;
  return (
    <>
      {/* Mobile Top Matter */}
      <MobileTitleBarOffset />
      {hero && (
        <MobileHeroImage
          src={hero}
          headerOnHero={false}
          landscapeCrop={landscapeCrop}
          data-testid={isHomePage ? 'cover-photo' : 'hero-image'}
        />
      )}
      <MobileAnnouncement announcement={announcement} />
      <MobileTopImage url={top} placeholderWidth={100} responsiveImages={topResponsive} alt="" />
      <MobileHeader heroVersion={false} />

      {/* Desktop Top Matter */}
      <DesktopAnnouncement announcement={announcement} />
      <DesktopTopImage
        url={top}
        placeholderWidth={100}
        responsiveImages={topResponsive}
        alt=""
        sm={768}
        md={1024}
        lg={1280}
        xl={1500}
      />
      {!displayHeaderOnHero && <DesktopHeader heroVersion={false} />}
      <Nav />

      {hero && (
        <>
          <HeroSpacer />
          <DesktopHeroImage
            src={hero}
            headerOnHero={displayHeaderOnHero}
            landscapeCrop={landscapeCrop}
            data-testid={isHomePage ? 'cover-photo' : 'hero-image'}
          />
        </>
      )}
    </>
  );
};

export default TopMatter;
